<div
  data-test="timeSelect"
  class="reminderModal__select reminderModal__inputFields reminderModal__inputPadding"
>
  <select id="timeSelect" [(ngModel)]="time" (ngModelChange)="onTimeChange($event)">
    <option *ngFor="let option of options" [value]="option">{{ option }}</option>
  </select>

  <pp-icon
    class="reminderModal__selectIcon"
    ppClass="setSize18"
    ppColor="grey-400"
    [ppSrc]="EIconPath.ICON_FIELDS_TIME_18"
  ></pp-icon>
</div>
