import { Component, Input } from '@angular/core';
import { WeekViewHourSegment } from 'calendar-utils';
import dayjs from 'dayjs';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PointReminderModalComponent } from 'src/app/project/modules/points/point-reminders/point-reminder-modal/point-reminder-modal.component';
import { TReminderModalData } from 'src/app/project/modules/points/point-reminders/point-reminder-modal/point-reminder-modal.consts';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';
import { TReminder } from '../../../reminders.consts';
import { TReminderEvent } from '../../reminder-calendar-consts';
import { RemindersCalendarService } from '../../reminders-calendar.service';

@Component({
  selector: 'pp-reminders-calendar-day-view-cell',
  templateUrl: './reminders-calendar-day-view-cell.component.html',
  styleUrl: './reminders-calendar-day-view-cell.component.scss',
})
export class RemindersCalendarDayViewCellComponent {
  @Input() ppLocale: string;
  @Input() ppEvents: TReminderEvent[];
  @Input() ppSegment: WeekViewHourSegment;
  daysInWeek = 1;
  displayDate: string;

  reminders: TReminder[] = [];
  isStart: boolean;

  constructor(
    private preferencesService: PreferencesService,
    private modalService: ModalService,
    private remindersCalendarService: RemindersCalendarService,
  ) {}

  ngOnChanges(): void {
    this.setReminders();

    const date = this.ppSegment.date;
    const hour = date.getHours();

    if (hour === 0) {
      this.isStart = true;
    }

    this.setDisplayDate();
  }

  setDisplayDate(): void {
    const dateFormat = this.preferencesService.getPreferences().dateFormat;

    const hourFormat =
      dateFormat === EDateFormat.EUROPEAN || dateFormat === EDateFormat.ISO ? 'H' : 'h A';

    this.displayDate = dayjs(this.ppSegment.displayDate).format(hourFormat);
  }

  createReminder(): void {
    this.modalService.setData<TReminderModalData>({
      pointId: null,
      timestamp: this.ppSegment.date.getTime(),
      skipTime: false,
    });
    this.modalService.showModal(PointReminderModalComponent);
  }

  private setReminders(): void {
    const hourInMs = 60 * 60 * 1000;
    const startDate = this.ppSegment.date.getTime();
    const endDate = this.ppSegment.date.getTime() + hourInMs;

    this.reminders = this.remindersCalendarService.sortReminders(
      this.ppEvents
        .map((event) => event.reminder)
        .filter((reminder) => {
          const reminderDate = reminder.timestampEpochMillis;
          return reminderDate >= startDate && reminderDate < endDate;
        }),
    );
  }
}
