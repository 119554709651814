import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { catchError, finalize, Observable, of, Subject, takeUntil } from 'rxjs';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TPreferences, TRemindersPreferences } from '../../../preferences/preferences.model';
import { UserSettingsService } from '../user-settings.service';

type TReminderSettingsOption = {
  header: string;
  subheader: string;
  enabled: boolean;
};

@Component({
  selector: 'pp-user-settings-reminders',
  templateUrl: './user-settings-reminders.component.html',
  styleUrl: './user-settings-reminders.component.scss',
})
export class UserSettingsRemindersComponent {
  options: TReminderSettingsOption[] = [
    {
      header: this.translationPipe.transform('reminder_alerts_settings_header'),
      subheader: this.translationPipe.transform('reminder_alerts_settings_subheader'),
      enabled: true,
    },
    // {
    //   header: this.translationPipe.transform('reminder_push_notifications_settings_header'),
    //   subheader: this.translationPipe.transform('reminder_push_notifications_settings_subheader'),
    //   enabled: true,
    // },
    // {
    //   header: this.translationPipe.transform('reminder_email_settings_header'),
    //   subheader: this.translationPipe.transform('reminder_email_settings_subheader'),
    //   enabled: true,
    // },
  ];

  preferences$: Observable<TPreferences>;
  private readonly destroy$ = new Subject<void>();
  private savingPreferences: boolean;

  constructor(
    private translationPipe: TranslationPipe,
    private store: Store<{
      preferences: TPreferences;
    }>,
    private userSettingsService: UserSettingsService,
    private modalService: ModalService,
  ) {
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));

    this.preferences$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_preferences) => this.preferencesHandler(_preferences));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  preferencesHandler(preferences: TPreferences): void {
    this.options[0].enabled = preferences.reminderSettings.alerts;
    // this.options[1].enabled = preferences.reminderSettings.pushNotifications;
    // this.options[2].enabled = preferences.reminderSettings.emails;
  }

  toggleOption(option: TReminderSettingsOption, index: number): void {
    if (this.savingPreferences) {
      return;
    }

    if (option.enabled || index !== 0) {
      this.confirmOptionChange(option);
    } else {
      this.showConfirmModal(option);
    }
  }

  private confirmOptionChange(option: TReminderSettingsOption) {
    option.enabled = !option.enabled;

    this.saveReminderPreferences();
  }

  saveReminderPreferences(): void {
    if (!this.savingPreferences) {
      this.savingPreferences = true;

      const reminderSettings: TRemindersPreferences = {
        alerts: this.options[0].enabled,
        // pushNotifications: this.options[1].enabled,
        // emails: this.options[2].enabled,
        pushNotifications: true,
        emails: true,
      };

      this.userSettingsService
        .saveReminderPreferences(reminderSettings)
        .pipe(
          catchError(() => of([])),
          finalize(() => {
            this.savingPreferences = false;
          }),
        )
        .subscribe();
    }
  }

  private showConfirmModal(option: TReminderSettingsOption): void {
    const modalData: TConfirmModalParams = {
      message: this.translationPipe.transform('modal_enable_reminder_setting_description'),
      heading: this.translationPipe.transform('modal_enable_reminder_setting_header'),
      confirmText: this.translationPipe.transform('confirm'),
    };

    this.modalService.setData(modalData);

    this.modalService.showModal(ConfirmModalComponent, {
      blur: false,
      callback: () => {
        this.confirmOptionChange(option);
      },
    });
  }
}
