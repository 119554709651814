<div class="snoozeButton" [id]="buttonId">
  <button
    class="snoozeButton__left"
    (click)="snooze({ snoozePeriod, bulk: false })"
    data-test="snoozeRemindersButton"
  >
    <span class="snoozeButton__text">{{ 'snooze' | ppTranslate }}</span>
  </button>

  <section
    class="snoozeButton__right"
    (click)="toogleSnoozeDropdown()"
    data-test="snoozeSelectListButton"
  >
    <pp-icon
      ppClass="setSize18"
      ppColor="grey-800"
      [ppSrc]="
        dropdown.visible && dropdown.buttonId === buttonId
          ? EIconPath.ICON_MISC_UP_ARROW_18
          : EIconPath.ICON_MISC_DOWN_ARROW_18
      "
    ></pp-icon>
  </section>
</div>
