<div class="reminderNavBar">
  <div class="reminderControlButtons">
    <div class="reminderControlButtons__left">
      <pp-site-filter-dropdown
        [ppDataFetched]="ppDataFetched"
        class="remindersFilterButton"
      ></pp-site-filter-dropdown>
      <span
        class="reminderControlButtons__text reminderControlButtons__icon"
        mwlCalendarToday
        [(viewDate)]="ppViewDate"
        (viewDateChange)="ppViewDateChange.emit($event)"
        >{{ 'today' | ppTranslate }}</span
      >

      <div class="reminderControlButtons__dateSwitcher">
        <pp-icon
          [ppSrc]="EIconPath.ICON_MISC_LEFT_ARROW_24"
          ppClass="setSize24"
          ppColor="grey-900"
          mwlCalendarPreviousView
          [view]="ppView"
          [(viewDate)]="ppViewDate"
          (viewDateChange)="ppViewDateChange.emit($event)"
          class="reminderControlButtons__icon"
        >
        </pp-icon>

        <div #periodDropdown [id]="buttonId">
          <h3
            class="reminderControlButtons__text reminderControlButtons__preview"
            [class.clickable]="ppView === CalendarView.Month"
          >
            {{ ppViewDate | calendarDate: ppView + 'ViewTitle' : ppLocale : 1 }}
          </h3>
        </div>

        <pp-icon
          [ppSrc]="EIconPath.ICON_MISC_RIGHT_ARROW_24"
          ppClass="setSize24"
          ppColor="grey-900"
          mwlCalendarNextView
          [view]="ppView"
          [(viewDate)]="ppViewDate"
          (viewDateChange)="ppViewDateChange.emit($event)"
          class="reminderControlButtons__icon"
        >
        </pp-icon>
      </div>

      <div class="reminderControlButtons__modeSwitcher">
        <button
          *ngFor="let mode of modes"
          class="reminderControlButtons__modeSwitcher__item"
          [class.reminderControlButtons__modeSwitcher__item--active]="ppView === mode.mode"
          [disabled]="ppView === mode.mode"
          (click)="changeMode(mode.mode)"
        >
          {{ mode.translationKey | ppTranslate }}
        </button>
      </div>
    </div>
  </div>

  <div class="reminderControlButtons__searchContainer">
    <pp-search-box
      [ppPlaceholder]="'search_for_reminder' | ppTranslate"
      (ppAction)="setKeyword($event)"
      ppSize="wide"
      ppColor="'000'"
    ></pp-search-box>
  </div>

  <div class="newReminderButton">
    <pp-button ppStyle="primary" (ppAction)="setReminder()" [ppDisabled]="!ppDataFetched">
      <pp-icon [ppSrc]="EIconPath.ICON_NAV_PLUS_14" ppColor="inherit" ppClass="setSize14"></pp-icon>
      {{ 'set_reminder' | ppTranslate }}
    </pp-button>
  </div>
</div>
