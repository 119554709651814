import { Component, OnInit } from '@angular/core';
import { finalize, tap } from 'rxjs';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { PointsLightweightService } from '../../../../points-lightweight.service';
import { TPoint, TPointLightweight } from '../../../../points.model';

type TPointPickerData = {
  name: string;
  id: string;
};

@Component({
  selector: 'pp-point-reminder-point-picker-dropdown',
  templateUrl: './point-reminder-point-picker-dropdown.component.html',
  styleUrl: './point-reminder-point-picker-dropdown.component.scss',
})
export class PointReminderPointPickerDropdownComponent implements OnInit {
  keyword = '';
  EIconPath = EIconPath;
  processing = false;

  filteredAccounts: (TPointPickerData & {
    workspaces: TPointPickerData[];
  })[] = [];

  collapsedAccounts: {
    [accountId: string]: boolean;
  } = {};

  filteredPoints: TPointLightweight[] = [];
  pickedWorkspace: TPointPickerData;

  private accounts: TAccount[];
  private workspaces: TWorkspacesById;
  private points: TPointLightweight[];
  private dropdown: TDropdown = this.dropdownService.getDropdown();

  constructor(
    private workspaceService: WorkspaceService,
    private accountService: AccountService,
    private dropdownService: DropdownService,
    private pointsLightweightService: PointsLightweightService,
  ) {}

  ngOnInit(): void {
    this.accounts = this.accountService.getAccounts();
    this.workspaces = this.workspaceService.getWorkspaces();

    this.filterAccounts();
  }

  setKeyword(keyword: string): void {
    this.keyword = keyword;

    if (this.pickedWorkspace) {
      this.filterPoints();
    } else {
      this.filterAccounts();
    }
  }

  toggleAccount(accountId: string): void {
    if (!this.collapsedAccounts[accountId]) {
      this.collapsedAccounts[accountId] = true;

      return;
    }

    this.collapsedAccounts[accountId] = !this.collapsedAccounts[accountId];
  }

  openWorkspace(workspaceId: string): void {
    this.filteredPoints = [];
    this.pickedWorkspace = {
      id: workspaceId,
      name: this.workspaces[workspaceId].siteName,
    };

    this.processing = true;

    this.pointsLightweightService
      .fetchPoints(workspaceId)
      .pipe(
        tap((points) => {
          this.points = points;

          this.filterPoints();
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  selectPoint(point: TPoint): void {
    this.dropdown.callback(point);
    this.dropdownService.hideDropdown();
  }

  back(): void {
    this.pickedWorkspace = null;
    this.filterAccounts();
  }

  private filterAccounts(): void {
    this.filteredAccounts = this.accounts.flatMap((account) => {
      const keywordMatch =
        account.accountName.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1;

      const filteredWorkspaces = account.workspaces
        .filter((workspaceId) => {
          const workspace = this.workspaces[workspaceId];

          return (
            keywordMatch ||
            workspace.siteName.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
          );
        })
        .map((workspaceId) => ({
          id: workspaceId,
          name: this.workspaces[workspaceId].siteName,
        }));

      return keywordMatch || filteredWorkspaces.length > 0
        ? [
            {
              id: account.accountId,
              name: account.accountName,
              workspaces: filteredWorkspaces,
            },
          ]
        : [];
    });
  }

  private filterPoints(): void {
    this.filteredPoints = this.points.filter((point) => {
      const keywordMatch = point.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1;
      const workspaceMatch =
        this.pickedWorkspace.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1;

      return keywordMatch || workspaceMatch;
    });
  }
}
