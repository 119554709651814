{
  "translation": {
    "profile_settings": "Profile Settings",
    "prompt_maintenance": "Maintenance in progress. Please try again in a moment.",
    "prompt_max_pins": "The maximum number of pins is 30.",
    "prompt_max_polygons": "The maximum number of areas is 30.",
    "prompt_empty_name": "Name cannot be empty.",
    "prompt_account_name_changed": "Account name has been changed.",
    "save_changes_failed": "Your changes could not be saved. Please try again.",
    "prompt_account_type_change": "Account type changed successfully.",
    "prompt_industry_type_change": "Industry type changed successfully.",
    "prompt_subscription_type_change": "Subscription type changed successfully.",
    "prompt_account_manager_change": "Account manager changed successfully",
    "prompt_feature_settings_updated": "Features settings updated successfully.",
    "prompt_email_invalid": "Enter valid email address.",
    "prompt_password_link_invalid": "The password reset link is no longer valid. If you have requested multiple password reset links, please click the link from the most recent email received.",
    "prompt_error": "An error occurred, please try again later.",
    "prompt_password_short": "The password must be at least 6 characters long.",
    "prompt_incorrect_credentials": "Incorrect email address and / or password.",
    "prompt_internet_connection": "Please check your internet connection and try again.",
    "prompt_server_error": "We're sorry, the server encountered a temporary error. If you're on a slow connection, please try refreshing the page. If the problem continues after a few minutes, please contact support@pinpointworks.com.",
    "prompt_email_unverified": "Oops! This email address has not yet been verified. Please check your email for the invitation to join Pinpoint Works. The reset password button may be used in the future once your email is verified.",
    "prompt_email_already_registered": "Email address already registered.",
    "register_unauthorized": "You are not authorised to register a new account.",
    "prompt_new_custom_field_taken": "Oops! This field name already exists. Please choose a different name.",
    "prompt_select_currency": "Please select a currency.",
    "prompt_fill_all_warning": "Please fill all field labels.",
    "prompt_field_created": "The field has been created.",
    "prompt_field_create_error": "The field has not been created. Please try again.",
    "prompt_field_create_error_timeline_doubled": "Only one Timeline Custom Field may be added to a site.",
    "prompt_field_create_error_timeline_not_enabled": "Timeline Custom Field is not enabled for the account.",
    "prompt_field_import_singular": "Custom Field imported successfully.",
    "prompt_field_import_multiple": "Custom Fields imported successfully.",
    "prompt_points_update_success": "Your points were updated successfully.",
    "prompt_points_update_error": "Your points were not updated.",
    "prompt_field_deleted": "The field has been deleted.",
    "prompt_field_deleted_error": "The field has not been deleted. Please try again.",
    "prompt_field_updated": "The field has been updated.",
    "prompt_field_updated_error": "The field has not been updated. Please try again.",
    "confirm_delete_site": "Are you sure you want to delete this site?",
    "prompt_site_deleted": "Site has been deleted.",
    "prompt_export_busy": "We are busy processing other export requests. Please try again in a few moments.",
    "prompt_export_error": "Points were not exported.",
    "prompt_export_will_shortly_receive_a_link_via_email": "The report is now being generated. Due to the size of this file, you will shortly receive a link via email to access this report.",
    "prompt_point_deleted": "The point has been deleted.",
    "prompt_point_deleted_error": "The point has not been deleted. Please try again.",
    "prompt_reminder_updated": "The reminder has been updated.",
    "prompt_reminder_updated_error": "The reminder has not been updated.",
    "prompt_reminder_created": "The reminder has been set.",
    "prompt_reminder_created_error": "The reminder has not been set.",
    "prompt_reminder_deleted": "The reminder has been deleted.",
    "prompt_reminder_deleted_error": "The reminder has not been deleted. Please try again.",
    "prompt_timeline_access_denied": "You no longer have access to Timeline feature.",
    "prompt_export_start": "The points are now being exported. Depending on the size of the export this may take a few moments.",
    "prompt_export_start_single": "The point is now being exported. Depending on the size of the export this may take a few moments.",
    "prompt_export_generation": "Your report will be generated shortly.",
    "prompt_point_created": "Your new point has been created.",
    "prompt_title_empty": "Title cannot be empty.",
    "prompt_attachments_uploading": "Uploading attachments in process.",
    "prompt_attachments_image_uploading": "Image uploading. Please try again in a few seconds.",
    "prompt_attachments_size_warning": "Files cannot be larger than 100 MB. Please compress your attachment and try again.",
    "prompt_attachments_size_info": "You have selected {{ size }} MB of media, your download will start shortly.",
    "prompt_attachments_rotation_in_progress": "Image rotation process is not finished yet. Please try again in a few seconds.",
    "prompt_offline_safari_upload": "Only images can be uploaded in offline mode on Safari.",
    "prompt_file_deleted": "The attachment has been deleted.",
    "prompt_attachments_rotation_failed": "The image has not been rotated.",
    "prompt_file_deleted_error": "The attachment has not been deleted. Please try again.",
    "prompt_file_deleted_multiple": "The attachments have been deleted.",
    "prompt_file_renamed": "The attachment has been renamed.",
    "prompt_file_renamed_error": "The attachment has not been renamed. Please try again.",
    "prompt_file_deleted_multiple_error": "The attachments have not been deleted. Please try again.",
    "prompt_comment_saved_error": "Could not send your comment. Please try again later.",
    "prompt_point_removed": "The point was removed.",
    "prompt_changes_error": "Your changes failed to save. Please check your internet connection and try again.",
    "prompt_activities_error": "Could not load activities.",
    "prompt_request_logged_out": "You've been logged out.",
    "prompt_access_error": "Oops! Could not load points due to access settings. Please contact your Admin user.",
    "prompt_message_deleted": "Your message has been deleted.",
    "prompt_comment_delete_error": "Could not delete comment.",
    "prompt_user_site_deleted": "The user has been deleted from the site.",
    "prompt_user_account_deleted": "The user has been deleted from the account.",
    "prompt_share_delete_error": "The share has not been deleted. Please try again.",
    "prompt_site_shared": "The site has been shared.",
    "prompt_share_error_already_shared": "This user already has the access to this site.",
    "prompt_share_error": "The site has not been shared. Please try again.",
    "prompt_share_import": "The users have been imported.",
    "prompt_share_import_error": "The users have not been imported. Please try again.",
    "prompt_annotation_error": "Could not get image annotation.",
    "prompt_annotation_save": "The image annotation saved successfully.",
    "prompt_annotation_save_error": "The image annotation was not saved. Please try again.",
    "prompt_annotation_get_error": "Could not get saved image annotation.",
    "prompt_rotation_in_progress": "Previous rotation process is not finished yet. Please try again in a few seconds.",
    "prompt_file_upload_error": "The attachment has not been uploaded. Please try again.",
    "prompt_file_size_too_large_error": "The attachment size is too large. Please reduce the attachment size and try again.",
    "prompt_site_name_change": "Site name has been changed.",
    "prompt_site_name_change_error": "Site name has not been changed. Please try again.",
    "prompt_site_name_change_already_exists": "Oops! This site name already exists. Please choose a different name.",
    "prompt_site_created": "The site has been created.",
    "prompt_site_created_error": "The site has not been created. Please try again.",
    "prompt_site_plan_created_error": "The site has been created, but there was a problem with uploading the site plan.",
    "prompt_site_name_taken": "A site with name # already exists.",
    "prompt_copy_failed": "Points failed to copy. Please try again later.",
    "prompt_request_in_progress": "There is another request in process. Please wait until it is finished.",
    "prompt_point_copy_success": "Points successfully copied.",
    "prompt_copy_permission_denied": "Oops! You do not have the necessary access level to copy points to the selected site. Please ensure you are an Admin or Normal user on the destination site to copy points.",
    "prompt_copy_size_limit": "The current size of your points is {{size}} MB. The maximum limit size for copying points is 100 MB. Please select a fewer number of points and try again.",
    "prompt_move_size_limit": "The current size of your points is {{size}} MB. The maximum limit size for moving points is 100 MB. Please select a fewer number of points and try again.",
    "prompt_points_delete": "The points have been deleted.",
    "prompt_points_delete_error": "The points have not been deleted. Please try again.",
    "prompt_points_move_error": "Points failed to move. Please try again later.",
    "prompt_move_permission_denied": "Oops! You do not have the necessary access level to move points to the selected site. Please ensure you are an Admin or Normal user on the destination site to move points.",
    "prompt_custom_fields_reorder": "Custom Fields reordered.",
    "prompt_custom_fields_reorder_error": "Custom Fields have not been reordered. Please try again.",
    "prompt_logo_PDF_update": "The PDF logo has been updated.",
    "prompt_logo_PDF_upload": "The PDF logo has been added.",
    "prompt_logo_PDF_update_error": "The PDF logo has not been updated. Please try again.",
    "prompt_site_plan_updated": "The site plan has been updated.",
    "prompt_site_plan_updated_error": "The site plan has not been updated. Please try again.",
    "prompt_invalid_character_comma": "\", \" character is not allowed. Please choose a different name.",
    "prompt_invalid_character_vertical_bar": "\"| \" character is not allowed. Please choose a different name.",
    "prompt_invalid_character_plus": "\"+ \" character is not allowed. Please choose a different name.",
    "prompt_invalid_character_slash_custom_field": "List values cannot contain the / character.",
    "prompt_new_tag_added": "New tag added.",
    "prompt_tag_name_taken": "Tag name already exists. Please choose a unique name.",
    "prompt_invitation_resend": "The invitation has been resent.",
    "prompt_invitation_resend_error": "The invitation has not been resent.",
    "prompt_tag_delete": "The tag has been deleted.",
    "prompt_tag_delete_error": "The tag has not been deleted. Please try again.",
    "prompt_tag_import_multiple": "Tags imported successfully.",
    "prompt_tag_import_single": "Tag imported successfully.",
    "prompt_site_access_denied": "You no longer have access to this site or the site does not exist. You will now be redirected.",
    "prompt_fleet_access_denied": "You no longer have access to the fleet dashboard. You will now be redirected.",
    "access_denied_redirect": "You no longer have access to this page. You will now be redirected.",
    "prompt_sync_site_data_success": "Site data was successfully synced.",
    "prompt_password_change": "Your password has been changed.",
    "prompt_password_error_match": "Old password doesn't match your current password.",
    "prompt_avatar_update": "Your avatar has been updated.",
    "prompt_avatar_update_error": "Your avatar could not be updated. Please try again.",
    "prompt_name_change_success": "Your name has been changed.",
    "prompt_site_plan_zoom_update": "Your Site Plan Zoom Speed setting has been updated.",
    "prompt_avatar_delete": "Your avatar has been deleted.",
    "prompt_avatar_delete_error": "Your avatar could not be deleted. Please try again.",
    "prompt_user_type_change_success": "User type has been changed.",
    "prompt_date_displayed_format_change_success": "Your Date Displayed Format setting has been updated.",
    "prompt_white_label_created_success": "White Label created successfully.",
    "prompt_white_label_created_error": "Failed to create white label.",
    "prompt_white_label_update": "White Label updated.",
    "prompt_white_label_update_error": "Failed to update white label.",
    "prompt_file_processing_error": "There was an error when processing this attachment.",
    "prompt_comment_empty_error": "Comment can't be empty.",
    "prompt_tag_over_limit_50": "The character limit has been exceeded. Please edit the tag to contain 50 characters or less.",
    "prompt_text_over_limit": "The character limit has been exceeded. Please edit the field to contain {{characterLimit}} characters or less.",
    "prompt_share_level_edit": "The user's access level has been edited.",
    "prompt_download_media_size": "Downloading #MB of media will start shortly.",
    "prompt_point_moved_deleted": "This point has been moved or deleted.",
    "prompt_site_plan_loading_error": "There was a problem with loading your site plan. Please contact support.",
    "prompt_point_title_missing": "Please add a point title.",
    "prompt_point_location_missing": "Please add a point location.",
    "prompt_assignees_updated_success": "Assignees have been updated.",
    "prompt_file_spreadsheet_invalid": "This file doesn't appear to be a proper spreadsheet.",
    "prompt_file_spreadsheet_invalid_error": "There was an error processing the file. Make sure it's a correct spreadsheet.",
    "prompt_point_status_update": "Status successfully changed.",
    "prompt_point_flag_update": "Flag successfully changed.",
    "prompt_point_title_update": "Title successfully changed.",
    "prompt_point_description_update": "Description successfully changed.",
    "prompt_point_tags_update": "Tags successfully changed.",
    "prompt_point_field_update": "Field successfully changed.",
    "prompt_point_text_update": "Text Custom Field successfully changed.",
    "prompt_point_rich_text_update": "Rich Text Custom Field successfully changed.",
    "prompt_point_list_update": "List Custom Field successfully changed.",
    "prompt_point_checkbox_update": "Checkbox Custom Field successfully changed.",
    "prompt_point_date_update": "Date Custom Field successfully changed.",
    "prompt_point_time_update": "Time Custom Field successfully changed.",
    "prompt_point_cost_update": "Cost Custom Field successfully changed.",
    "prompt_point_numbers_update": "Numbers Custom Field successfully changed.",
    "prompt_point_percentage_update": "Percentage Custom Field successfully changed.",
    "prompt_point_timeline_update": "Timeline Custom Field successfully changed.",
    "prompt_point_custom_field_update": "Custom Field successfully changed.",
    "prompt_point_priority_update": "Priority successfully changed.",
    "prompt_point_location_update": "Location successfully changed.",
    "prompt_point_moved_success": "Point successfully moved.",
    "prompt_changes_error_field_deleted": "Oops! This Custom Field has been deleted by an Admin user.",
    "prompt_changes_permission_denied": "You cannot make changes to this point due to access settings. Please contact your Admin user.",
    "prompt_upload_incorrect_file": "Oops! Invalid attachment type for upload.",
    "prompt_sites_archived": "The selected site(s) have been archived.",
    "prompt_sites_unarchived": "The selected site(s) have been unarchived.",
    "no_search_result": "No search results.",
    "delete_pins_warning": "Switching mode to area will remove all pins you have previously added to this point.",
    "delete_area_warning": "Switching mode to pins will remove the area you have previously added to this point.",
    "prompt_sw_unrecoverable": "Oops! There was an error with cached data. Please reload the page.",
    "open": "Open",
    "in_progress": "In Progress",
    "to_review": "To Review",
    "on_hold": "On Hold",
    "completed": "Completed",
    "canceled": "Canceled",
    "prompt_account_shares_error": "Could not load account users.",
    "prompt_user_shares_edited": "User & Site permissions edited successfully",
    "prompt_user_shares_created": "User & Site permissions created successfully",
    "prompt_user_shares_edited_error": "User account shares have not been edited.",
    "prompt_share_add_error": "Failed to create user share.",
    "prompt_no_sites": "It looks like you no longer have access to any Sites. Please contact your Admin user for an invite.",
    "tooltip__account_users": "When selecting unselected site(s), clicking Save will add the user to the selected site(s). When deselecting a site, clicking Save will delete the user from the selected site(s).",
    "invite": "Invite",
    "import_users": "Import users",
    "cancel": "Cancel",
    "advanced": "Advanced",
    "edit_users": "Edit users",
    "edit_user": "Edit user",
    "save": "Save",
    "access_level": "Access level",
    "email": "Email",
    "share_admin_description": "These users have access to the site's settings - site name, tags, Custom Fields, and users. They can add, edit and delete points. They can also copy and move points from one site to another.",
    "share_normal_description": "These users can add and edit points. They cannot access site settings or delete points.",
    "share_guest_description": "These users can comment and add files to points. They cannot add, edit or delete points. They do not have access to the site settings.",
    "tags": "Tags",
    "tags_limited_description": "Limit User to only see points with the selected Tags:",
    "tags_limited_tooltip": "Users with limited tags cannot edit tags on mobile.",
    "activity": "Activity",
    "show_activity": "Show Activity",
    "comments": "Comments",
    "hide_comments": "Hide Comments",
    "view_comments": "View Comments",
    "make_comments": "Make Comments",
    "hide_comments_description": "The user will not be able to see or make comments.",
    "view_comments_description": "The user can see all comments but will not be able to make comments.",
    "make_comments_description": "The user can make comments and view all comments.",
    "advanced_access": "Advanced access",
    "custom_field_list_description": "Choose which fields should be visible or editable to the user.",
    "field": "Field",
    "hide": "Hide",
    "view_only": "View only",
    "allow": "Allow",
    "edit": "Edit",
    "back_to_user_import": "Back to Import user list",
    "select_site": "Select site",
    "users_selected": "users selected",
    "user_selected": "user selected",
    "back_to_site_selection": "Back to site selection",
    "back_to_site": "Back to Site",
    "select_user_import_src": "Select which users to import from",
    "import_users_warning_text_1": "Be aware that when you import a user from another site only access level settings pertaining to ",
    "import_users_warning_text_2": " and ",
    "import_users_warning_text_3": " that exist on both sites can be imported. If certain Tags and Custom Fields do not exist on the destination site, they will ",
    "import_users_warning_text_4": "not be imported",
    "import_users_warning_text_5": " from the original site.",
    "import": "Import",
    "custom_fields": "Custom Fields",
    "and": "and",
    "from": "from",
    "From": "From",
    "user": "User",
    "user_lowercase": "user",
    "users_lowercase": "users",
    "access": "Access",
    "accessible_tags": "Accessible tags",
    "action": "Action",
    "all": "All",
    "none": "None",
    "more": "more",
    "Delete": "Delete",
    "delete_warning": "Are you sure you want to delete",
    "close_without_saving": "Close without saving",
    "close_point_modal_description": "Are you sure you want to close this point without saving?",
    "change_status": "Change status",
    "change_flag_status": "Change Flag Status",
    "change_priority": "Change priority",
    "bulk_changes_custom_fields": "Bulk changes to Custom Fields",
    "add_tags": "Add tags",
    "copy_points": "Copy points",
    "move_points": "Move points",
    "delete_points": "Delete points",
    "user_already_exists_site": "This user already exists on this site",
    "user_already_selected": "You have already selected a user with this email",
    "site": "Site",
    "plan": "Plan",
    "url_export_info": "A hyperlink will be added to the point ID. Any user who has access to the point can click the ID on the PDF export and the point will open in Pinpoint Works. Users who do not have access to the point will not be able to open the point in Pinpoint Works.",
    "image_dates_export_info": "When toggled on, this will only export images from the dates chosen in the filter menu. Please use the \"Updated Date\" filter to select dates. When toggled off, all images will be exported.",
    "site_settings_custom_fields_tooltip_1": "This is where you define Custom Fields for your site. Add as few or as many as needed. Choose from ten types of Custom Field:",
    "text_field": "Text",
    "text_field_description": "A simple, free-type text field.",
    "list_field": "List",
    "list_field_description": "A drop down list with list and sub-list options.",
    "date_field": "Date",
    "date_field_description": "A calendar date input field.",
    "cost_field": "Cost",
    "cost_field_description": "A currency value in GBP, EUR or USD.",
    "rich_text": "Rich Text",
    "rich_text_description": "Multiple line text field using rich text options.",
    "checkbox": "Checkbox",
    "checkbox_description": "A single checkbox shown in the table.",
    "time": "Time",
    "time_description": "Track time in days, hours and minutes.",
    "numbers": "Numbers",
    "numbers_description": "For numerical data with options for decimals and units.",
    "percentage": "Percentage",
    "percentage_description": "Track full integers from 0-100%.",
    "timeline": "Timeline",
    "timeline_CF_description": "Track start and finish dates for works which will appear on the Timeline. This is an add-on feature.",
    "tip": "Tip",
    "site_settings_custom_fields_tooltip_2": "We recommend using a ‘List’ rather than a ‘Text’ Custom Field for defined information. This allows more control over the data input by the site's users and to better sort and filter the work list.",
    "tag_settings_1": "Use tags to sort, order, and filter the work list. Add as few or as many tags as needed. Tags are limited to 50 characters.",
    "tag_settings_2": "Tags are also important for inviting Guest with Tag users who can only see points with specified tags.",
    "tag_settings_3": "As an example, you may want to add tags for different departments (Deck, Interior, Engineering), contractors (Company 1, Company 2, Company 3), or type of work (electrical, plumbing, refit, warranty).",
    "attachments_upload": "Attachments successfully uploaded.",
    "attachment_upload": "Attachment successfully uploaded.",
    "unread": "Unread",
    "i_was_mentioned": "I was mentioned",
    "mentioned": "Mentioned",
    "assigned_to_me": "Assigned to me",
    "assigned": "Assigned",
    "delete_attachments": "Delete attachments",
    "delete_attachments_confirm": "Are you sure you want to delete the selected attachments?",
    "delete_attachment": "Delete attachment",
    "delete_attachment_confirm": "Are you sure you want to delete the selected attachment?",
    "group": "Group",
    "points_to_be_deleted": "Points to be deleted:",
    "confirm_area_delete": "Are you sure you want to delete the entire area?",
    "delete_area": "Delete area",
    "prompt_search_failed": "Failed to search for keyword",
    "site_plan": "Site Plan",
    "export": "Export",
    "site_plan_show": "Show the Site Plan",
    "show_export": "Show the Export Button",
    "site_plan_AAL_description": "When toggled on, the user can see the Site Plan. When toggled off, the user cannot see the Site Plan.",
    "export_AAL_description": "When toggled on, the user will be able to see the Export button and have full access to export options. When toggled off, the Export button will be hidden from the user.",
    "activity_AAL_description": "When toggled on, the user will be able to see the Activity section on a Point. When toggled off, the Activity section will be hidden from the user.",
    "user_management_tags_AAL_description": "Choose whether Tags on this site should be visible or editable to the user",
    "export_permission_denied": "You do not have the permission to export points.",
    "flag_clash": "You must select points with the same flag.",
    "flag_no_permission": "You do not have permission to change flagged status of points.",
    "export_filtered_warning": "Not all points will be exported due to the your access level.",
    "account_settings": "Account Settings",
    "back_to_account_settings": "Back to Account settings",
    "cookie_preferences": "Cookie Preferences",
    "help_centre": "Help Centre",
    "whats_new": "What's New",
    "upgrade": "Upgrade",
    "contact_us": "Contact us",
    "logout": "Logout",
    "version": "Version",
    "email_notification_frequency": "Email notification frequency:",
    "instant_notifications": "Instant digest",
    "notifications_digest_six": "Six-hour digest",
    "notifications_digest_day": "Daily digest",
    "notification_digest_week": "Weekly digest",
    "notification_none": "No digest",
    "new_dashlet": "New Dashlet",
    "set_default_layout": "Set default layout",
    "confirm_default_layout": "This will reset the Dashboard to the default layout and remove any changes you've made to dashlet settings or layout. Any changes made to the Dashboard after resetting will be saved automatically.",
    "no_custom_fields_available": "There are no Custom Fields available for this site.",
    "no_tags_available": "There are no tags available for this site.",
    "no_users_available": "There are no users available for this site.",
    "site_currently_on": "This is the site you are currently on",
    "fields_selected": "fields selected",
    "field_selected": "field selected",
    "tags_selected": "tags selected",
    "tag_selected": "tag selected",
    "added": "Added",
    "photo_360": "360 Photo",
    "photos_360": "360 Photos",
    "to_a_point_you": "to a point you",
    "are_assigned_to": "are assigned to",
    "are_subscribed_to": "are subscribed to",
    "from_a_point_you": "from a point you",
    "removed": "Removed",
    "export_dashboard": "Export dashlets",
    "dashlet_points_created": "New points created",
    "dashlet_export_points_created": "Points Created",
    "dashlet_comments_created": "Comments that were made",
    "dashlet_points_completed": "Points that were marked as completed",
    "dashlets_live_status": "Live data: Status",
    "dashlets_live_priority": "Live data: Priority",
    "dashlet_over_time_status": "Status over time",
    "dashlet_over_time_priority": "Priority over time",
    "dashlet_points_high": " Points that were changed to high priority ",
    "dashlet_points_medium": " Points that were changed to medium priority",
    "dashlet_points_low": " Points that were changed to low priority ",
    "dashlet_points_on_hold": "Points that were set as On Hold",
    "dashlet_points_in_progress": "Points that were set as In Progress",
    "dashlet_points_open": "Points that were set as Open",
    "dashlet_points_to_review": "Points that were set as To Review",
    "dashlet_points_canceled": "Points that were set as Canceled",
    "dashlet_points_updated": "Points that were updated",
    "dashlet_points_deleted": "Points that were deleted",
    "dashlets_activities": "Activities",
    "prompt_export_error_dashboard": "Dashboard was not exported.",
    "page_layout": "Page layout",
    "select_dashlet_to_export": "Select Dashlets to export",
    "select_assets_to_export": "Select Assets to export",
    "select_items_to_export": "Select items to export",
    "simple_dashlets": "Simple Dashlets",
    "advanced_dashlets": "Advanced Dashlets",
    "share_add_failed": "Oops! The share failed to save for {{email}} on the {{siteName}} site. Please refresh the browser and try adding the user again.",
    "share_add_failed_shared": "Oops! The site {{siteName}} is already shared for {{email}}.",
    "create": "Create",
    "list_items": "List items",
    "alphabetize_items": "Alphabetize all items",
    "number_of_decimal_places": "Number of decimal places",
    "show_hours_only": "Show hours only",
    "show_total_in_table_header": "Show Total in Table Footer",
    "add_comma_between_numbers": "Add comma between numbers",
    "choose_unit": "Choose a unit",
    "choose_currency": "Choose Currency",
    "custom_field_name": "Custom Field Name",
    "create_list_from_excel": "Create list from excel",
    "choose_type": "Choose Type",
    "back_to_custom_field_selection": "Back to Custom Field selection",
    "create_custom_field": "Create Custom Field",
    "toggle_off_filter": "Toggle on to filter points which have an empty value for this field.",
    "custom_field_used_on_sites": "This Custom Field is used on the following sites:",
    "custom_field_used_on_sites_name": "{{name}} Custom Field is used on the following sites:",
    "priority": "Priority",
    "status": "Status",
    "id": "ID",
    "title": "Title",
    "point_id": "Point ID",
    "description": "Description",
    "created": "Created",
    "updated": "Updated",
    "createdBy": "Created By",
    "assignees": "Assignees",
    "high": "High",
    "medium": "Medium",
    "low": "Low",
    "comments_made": "Comments Added",
    "points_completed": "Points Completed",
    "points_changed_to_high": "High Priority Points",
    "points_changed_to_medium": "Medium Priority Points",
    "points_changed_to_low": "Low Priority Points",
    "points_changed_to_on_hold": "Points put On Hold",
    "dashboard_export_points_changed_to_on_hold": "Points On Hold",
    "points_changed_to_in_progress": "Points changed to In Progress",
    "dashboard_export_points_changed_to_in_progress": "Points In Progress",
    "points_changed_to_open": "Points changed to Open",
    "dashboard_export_points_changed_to_open": "Points Open",
    "points_changed_to_review": "Points set as To Review",
    "dashboard_export_points_changed_to_review": "Points To Review",
    "points_changed_to_canceled": "Points Canceled",
    "total_points_number": "Total Points: {{totalPoints}}",
    "today": "Today",
    "this_week": "This Week",
    "last_7_days": "Last 7 days",
    "this_month": "This Month",
    "last_30_days": "Last 30 days",
    "this_year": "This Year",
    "last_365_days": "Last 365 days",
    "point_created_one": "New Point was created",
    "comment_created_one": "Comment was made",
    "points_changed_high_priority_other": "Points were changed to high priority",
    "points_changed_medium_priority_other": "Points were changed to medium priority",
    "points_changed_low_priority_other": "Points were changed to low priority",
    "points_changed_high_priority_one": "Point was changed to high priority",
    "points_changed_medium_priority_one": "Point was changed to medium priority",
    "points_changed_low_priority_one": "Point was changed to low priority",
    "points_changed_to_open_dashboard_other": "Points were changed to Open",
    "points_changed_to_open_dashboard_one": "Point was changed to Open",
    "points_changed_to_in_progress_dashboard_other": "Points were changed to In Progress",
    "points_changed_to_in_progress_dashboard_one": "Point was changed to In Progress",
    "points_changed_to_on_hold_dashboard_other": "Points were put On Hold",
    "points_changed_to_on_hold_dashboard_one": "Point was put On Hold",
    "points_changed_to_completed_dashboard_other": "Points Completed",
    "points_changed_to_completed_dashboard_one": "Point Completed",
    "points_changed_to_review_dashboard_other": "Points were set as To Review",
    "points_changed_to_review_dashboard_one": "Point was set as To Review",
    "points_changed_to_canceled_dashboard_other": "Points Canceled",
    "points_changed_to_canceled_dashboard_one": "Point Canceled",
    "apply": "Apply",
    "all_sites": "All Sites",
    "select_time_period": "Select Time Period",
    "choose_sites_data_display": "Choose which sites you want to display the data from",
    "all_accounts_sites": "All Accounts - All Sites",
    "select_account_site": "Select Account & Site",
    "days": "Days",
    "weeks": "Weeks",
    "months": "Months",
    "year": "Year",
    "dashboard": "Dashboard",
    "reminders": "Reminders",
    "site_settings": "Site settings",
    "new_site": "New Site",
    "overview": "Overview",
    "trial": "Trial",
    "subscribed": "Subscribed",
    "unsubscribed": "Unsubscribed",
    "test": "Test",
    "unset": "Unset",
    "move_points_tooltip_1": "You must be an Admin user or Normal user on the destination site in order to move points.",
    "move_points_tooltip_2": "If tag and Custom Field information should be moved, please create these in the destination site exactly as they are shown in the source site before moving points. Otherwise, these tags and Custom Fields will be deleted.",
    "copy_points_tooltip_1": "You must be an Admin user or Normal user on the destination site in order to copy points.",
    "copy_points_tooltip_2": "If tag and Custom Field information should be copied, please create these in the destination site exactly as they are shown in the source site before copying points. Otherwise, these tags and Custom Fields will not be copied.",
    "select_destination_site": "Select a destination site",
    "move_without_attachments": "Move points without attachments",
    "site_plans_different": "The site plans are different. In this case, the location pins will be pinned to the center of the destination site plan.",
    "information_transfer_1": "Information will only transfer if:",
    "information_transfer_2": "The same tags exist on both sites.",
    "information_transfer_3": "Custom Field types and names match.",
    "information_transfer_4": "List Custom Field names and values match.",
    "information_transfer_5_move": "If an assigned user is not found on the destination site, their assignee information will not be moved.",
    "information_transfer_5_copy": "If an assigned user is not found on the destination site, their assignee information will not be copied.",
    "information_transfer_6": "Please ensure the information listed below matches before transferring points. Otherwise the information will not transfer.",
    "request_being_processed": "Your request is being processed.",
    "move": "Move",
    "copy_link_to_point": "Copy link to point",
    "confirm": "Confirm",
    "copy_without_attachments": "Copy points without attachments",
    "copy": "Copy",
    "clear_all": "Clear all",
    "done": "Done",
    "activities_cannot_be_exported": "Activities dashlet cannot be exported",
    "side_panel_left": "Left part of side panel",
    "side_panel_right": "Right side of panel and header",
    "left_panel_hover": "Hover colour in left panel",
    "left_panel_active_color": "Active colour in left panel",
    "font_family": "Font family",
    "web_logo_upload": "Upload Web logo",
    "web_logo": "Web logo",
    "website": "Website",
    "website_update": "Update website",
    "top_right_logo": "PDF logo #2 (top right logo)",
    "white_label_update": "Update white label",
    "white_label_create": "Create white label",
    "admin": "Site Admin",
    "normal": "Normal",
    "guest": "Guest",
    "owner": "Owner",
    "email_address": "Email Address",
    "unlimited": "Unlimited",
    "limited": "Limited",
    "education": "Education",
    "marine_commercial": "Marine - Commercial",
    "marine_cruise": "Marine - Cruise",
    "marine_small_production": "Marine - Small Production",
    "marine_super_yacht": "Marine - Super Yacht",
    "marine_other": "Marine - Other",
    "property_commercial": "Property - Commercial",
    "property_hotel": "Property - Hotel",
    "property_residential": "Property - Residential",
    "property_other": "Property - Other",
    "other": "Other",
    "account_type": "Account type",
    "account_type_choose": "Choose account type...",
    "subscription_type": "Subscription type",
    "industry_type": "Industry type",
    "industry_type_choose": "Choose industry type...",
    "account_manager": "Account Manager",
    "global_table_preferences": "Global table preferences",
    "offline": "Offline",
    "luerssen_signature": "Luerssen signature",
    "aal_disable_site_plan": "AAL: disable Site Plan",
    "aal_disable_export": "AAL: disable Export button",
    "prompt_account_update": "Account updated successfully",
    "account_name": "Account name",
    "general": "General",
    "data_unavailable_offline": "Data not available in the offline mode.",
    "extra_features": "Extra features",
    "add_new_site": "Add new site",
    "current_sites": "Current sites",
    "name": "Name",
    "total_points": "Number of points",
    "used_storage": "Size",
    "tm_unit": "TM Unit",
    "integrations": "Integrations",
    "white_label": "White label",
    "reminders_due_date": "Reminders due date",
    "clear": "clear",
    "date_created": "Date Created",
    "date_updated": "Date Updated",
    "location": "Location",
    "points_with_location": "Points with location",
    "points_without_location": "Points without location",
    "sites": "Sites",
    "select_all": "Select all",
    "deselect_all": "Deselect all",
    "selected": "Selected",
    "selected_lowercase": "selected",
    "minimum": "min",
    "maximum": "max",
    "select": "Select",
    "to": "to",
    "filter": "Filter",
    "filters": "Filters",
    "including_tags": "Including Tags",
    "no_tags_created_for_site": "No tags have been created for this site",
    "no_additional_tags_to_display": "No additional tags to display.",
    "filter_points_with_tags": "Filter points with tags...",
    "combine_selected_tags": "Combine the selected tags",
    "combine_selected_tags_description": "Combine tags to filters points that include all of the selected tags. Deselect this option to filter points with any of the selected tags.",
    "excluding_tags": "Excluding Tags",
    "filter_points_without_tags": "Filter points without tags...",
    "combine_tags_description_exclude": "Combine tags to filters points that exclude all of the selected tags. Deselect this option to filter points without any of the selected tags.",
    "search_this_site": "Search this site",
    "search_overview": "Search Overview",
    "all_accounts": "All Accounts",
    "tester": "Tester",
    "developer": "Developer",
    "customer": "Customer",
    "SIMPLE_DASHLETS": "SIMPLE DASHLETS",
    "ADVANCED_DASHLETS": "ADVANCED DASHLETS",
    "simple_dashlets_modal_tab": "Simple Dashlet Modal Tab",
    "advanced_dashlet_modal_tab": "Advanced Dashlet Modal Tab",
    "new_dashlet_simple_description": "To see individual data for a specific site or all sites combined, add the following dashlets.",
    "new_dashlet_advanced_description": "To see comparative data for one site, all sites, or all account, add the following dashlets.",
    "multiple_dashlets_may_be_added": "Multiple dashlets may be added.",
    "add": "Add",
    "added_comments": "Added Comments",
    "high_priority": "High Priority",
    "medium_priority": "Medium Priority",
    "low_priority": "Low Priority",
    "created_points": "Created Points",
    "removed_point": "Removed this point.",
    "copied_this_point_to": "Copied this point to",
    "moved_this_point_to": "Moved this point to",
    "moved_this_point_from": "Moved this point from",
    "added_this_point": "Added this point.",
    "comment": "Comment",
    "deleted_user": "Deleted User",
    "changed": "Changed",
    "file": "File",
    "files": "Files",
    "images": "images",
    "image": "image",
    "images_360": "360 images",
    "image_360": "360 image",
    "changed_location": "Changed the location",
    "title_changed_to": "Title was changed to",
    "videos": "Videos",
    "video": "video",
    "show_more": "Show more",
    "show_less": "Show less",
    "no_recent_activity": "No recent activity",
    "load_more": "Load more",
    "week": "Week",
    "point_copy_limit_exceeded": "You can't copy more than 200 points.",
    "point_move_limit_exceeded": "You can't move more than 200 points.",
    "browser_not_supported": "Your browser is not supported",
    "recommend_browsers": "We recommend using Pinpoint Works with one of the following browsers:",
    "we_also_support": "We also support:",
    "ignore": "Ignore",
    "browser_not_supported_bar_1": "Your browser is not supported by the app. Please click",
    "browser_not_supported_bar_2": "here",
    "browser_not_supported_bar_3": "to check the list of supported browsers.",
    "here": "here",
    "dismiss": "Dismiss",
    "update_action_bar_1": "New application version is available. Click",
    "update_action_bar_2": "here",
    "update_action_bar_3": "to update.",
    "no_custom_fields_available_on_site": "No Custom Fields are available on this site.",
    "select_custom_fields_from": "Select Custom Fields from",
    "type": "Type",
    "timeline_not_enabled": "Timeline is not enabled for this account.",
    "custom_field_already_exists_on_site": "This Custom Field already exists on this site",
    "custom_field_with_name_already_selected": "You have already selected a Custom Field with this name",
    "sort_list_alphabetically": "Sort the list alphabetically",
    "list_item": "List item",
    "add_subitem": "Add subitem",
    "select_list_item": "Select list item...",
    "edit_custom_field": "Edit Custom Field",
    "add_item": "Add item",
    "name_your_custom_field": "Name your Custom Field",
    "unit_optional": "Unit (optional)",
    "add_new_cf_capital": "ADD NEW CUSTOM FIELD",
    "import_cf_capital": "IMPORT FROM OTHER SITES",
    "enable_timeline": "To enable the Timeline feature, contact us",
    "thank_you": "Thank you",
    "password_reset_1": "If the email account entered exists in our database, a password reset email was successfully sent.",
    "password_reset_2": "Please check the Inbox/Spam/Junk folder associated with the account.",
    "password_reset_3": "If the email account does not already have access, please contact the site's Admin User to gain access.",
    "reset_your_password": "Reset password",
    "password_reset_4": "Enter your email address below and we'll send you a link with instructions on how to reset your password",
    "submit": "Submit",
    "return_to_log_in": "Return to Log In",
    "create_login_details": "Create Login Details",
    "reset_password": "Reset password",
    "create_username": "Create Username",
    "create_password": "Create Password",
    "create_new_password": "Create a new password which you will later use to login.",
    "confirm_password": "Confirm Password",
    "confirm_new_password": "Confirm New Password",
    "passwords_dont_match": "Passwords don't match",
    "accept": "Accept",
    "terms_and_conditions": "Terms & Conditions",
    "privacy_policy": "Privacy Policy",
    "keep_me_updated": "Keep me updated on product news and features.",
    "sign_in": "Sign in",
    "return_to_login": "Return to Login",
    "your_password_must": "Your password must include:",
    "password_limit_1": "8 characters minimum",
    "password_limit_2": "1 upper-case letter",
    "password_limit_3": "1 lower-case letter",
    "password_limit_4": "1 number",
    "email_sent_to": "We've sent a confirmation email to:",
    "check_your_inbox": "Check your Inbox and click on the link to get started.",
    "check_your_email": "Check your email",
    "having_problem_email": "Having problems receiving our email? Have you tried these...",
    "having_problem_email_1": "Check your Junk Folder",
    "having_problem_email_2": "Be patient, it may take a few minutes to arrive",
    "having_problem_email_3": "Add: support@pinpointworks.com to your safe sender list",
    "sign_up_another_account": "Sign up with another email account",
    "here_to_help": "We're always here to help:",
    "support_email": "support@pinpointworks.com",
    "create_account": "Create your account",
    "full_name": "Full name",
    "password": "Password",
    "show_password": "Show password",
    "hide_password": "Hide password",
    "already_user": "Already a user? Log In",
    "sign_up": "Sign up",
    "you_can_now_log_in": "You can now log in to your Pinpoint works account",
    "log_in": "Log in",
    "verify_link_invalid_1": "The link is not valid or the account has already been verified. Please return to the",
    "verify_link_invalid_2": "login page",
    "verify_link_invalid_3": "to login with your credentials.",
    "remember_me": "Remember me",
    "forgot_password": "Forgot password?",
    "choose_format": "Choose format...",
    "page_format": "Page format:",
    "include": "Include:",
    "images_size": "Size of images:",
    "export_images_based_on_date": "Export images based on filter date",
    "add_url_link": "Add URL link",
    "cant_place_pins_outside": "You can't place pins outside of site plan",
    "click_to_select": "Click to select...",
    "import_from_excel": "IMPORT FROM EXCEL/CSV",
    "import_tags": "Import tags",
    "click_to_upload_site_file": "Click to upload site file",
    "or": "or",
    "drag_and_drop_here": "Drag and drop here",
    "back_to_excel_csv_import": "Back to Excel/CSV import",
    "select_tags_from": "Select tags from",
    "import_tags_from_excel": "Import tags from Excel/CSV",
    "tag_already_selected_on_different_site": "This tag has already been selected on a different site",
    "select_tags": "Select tags",
    "tag": "tag",
    "the": "the",
    "of_a_point_you": "of a point you",
    "were": "were",
    "you": "you",
    "assigned_lowercase": "assigned",
    "to_a_point": "to a point",
    "the_following": "the following",
    "a": "a",
    "file_uppercase": "File",
    "files_uppercase": "Files",
    "commented": "Commented",
    "on_a_point_you": "on a point you",
    "you_in_a": "you in a",
    "liked": "Liked",
    "reacted_to": "Reacted to",
    "your": "your",
    "set": "Set",
    "you_in": "you in",
    "cleared": "Cleared",
    "checked": "Checked",
    "unchecked": "Unchecked",
    "mark_as_read": "Mark as read",
    "mark_as_unread": "Mark as unread",
    "clear_notification": "Clear notification",
    "clear_all_notifications": "Clear all notifications",
    "confirm_clear_all_notifications": "Are you sure you want to clear all notifications?",
    "respond": "Respond",
    "comment_sent": "Comment Sent",
    "an": "an",
    "image_uppercase": "Image",
    "images_uppercase": "Images",
    "point": "Point",
    "custom_field": "Custom Field",
    "you_in_the": "you in the",
    "created_point": "Created Point",
    "edited_point": "Edited Point",
    "changed_title_point_assigned": "Changed the Title of a point you are assigned to:",
    "changed_title_point_created": "Changed the Title of a point you created:",
    "changed_status": "Changed Status.",
    "changed_priority": "Changed Priority.",
    "changed_description": "Changed Description.",
    "added_comment": "Added Comment:",
    "changed_location_notification": "Changed Location.",
    "changed_attachment": "Changed Attachment.",
    "changed_videos": "Changed videos.",
    "changed_images": "Changed images.",
    "changed_documents": "Changed documents.",
    "changed_images_360": "Changed images 360.",
    "changed_tags": "Changed Tags.",
    "changed_custom_fields": "Changed Custom Fields.",
    "you_were_mentioned_point": "You were mentioned in a point.",
    "changed_assignees": "Changed Assignees.",
    "in_a_custom_field": "in a Custom Field.",
    "point_was_deleted": "Point was deleted.",
    "in_a_comment": "in a comment.",
    "in_description": "in description.",
    "changed_pin_location": "Changed pin location.",
    "changed_an_area": "Changed an Area.",
    "to_a_new_point": "to a new point.",
    "you_in_a_new_point": "you in a new point.",
    "unlike_comment": "Unlike comment",
    "like_comment": "Like comment",
    "just_now": "Just now",
    "prompt_error_opening_point": "There was an error with opening this point.",
    "change": "Change",
    "change_password_for": "Change password for",
    "old_password": "Old password",
    "new_password": "New password",
    "avatar": "Avatar",
    "change_avatar": "Change avatar",
    "profile": "Profile",
    "change_password": "Change Password",
    "preferences": "Preferences",
    "site_plan_zoom_speed": "Site Plan Zoom Speed",
    "site_plan_zoom_speed_description": "Adjust the speed to zoom in and out of the site plan. For example, small site plans may want to zoom in more slowly while larger site plans may prefer to zoom in more quickly.",
    "date_display_format": "Date Displayed Format",
    "user_type": "User Type",
    "choose_user_type": "Choose user type...",
    "my_sites": "My Sites",
    "no_sites_found": "No sites found",
    "logout_offline_info": "After you logout all stored data for offline usage will be cleared.",
    "notifications": "Notifications",
    "no_access_site": "It looks like you no longer have access to any Sites.",
    "contact_admin_for_invite": "Please contact your Admin user for an invite.",
    "delete_folder": "Delete folder",
    "delete_folder_message_1": "If you delete the",
    "delete_folder_message_2": "folder, the sites within will be moved under the main account name.",
    "hide_site": "Hide Site",
    "email_notification": "Email Notifications",
    "data_not_available": "Data not available",
    "delete_reminder": "Delete reminder",
    "delete_reminder_confirmation": "Are you sure you want to delete this reminder?",
    "users": "Users",
    "settings_users_1": "View, add, edit and delete your site's users here. If you've paid for a Per Project subscription, you can add as many users as needed.",
    "settings_users_2": "If you've paid for Per User subscription, only the number of Site Admin and Normal users paid for may be added to your site. To add more, contact support@pinpointworks.com. <i>Guest</i> and <i>Guest with Tags</i> users may be added free of charge.",
    "search_users": "Search Users",
    "add_new_user": "Add new user",
    "edit_site_name": "Edit site name",
    "add_user": "Add user",
    "footer_logo": "PDF logo (footer logo)",
    "pdf_logo_header": "PDF logo (header logo)",
    "manage_tags": "Manage Tags",
    "manage_custom_fields": "Manage Custom Fields",
    "manage_users": "Manage Users",
    "you_have": "You have",
    "reminder": "Reminder",
    "due_today": "due today.",
    "go_to_reminders": "Go to Reminders",
    "optional": "optional",
    "previous_week": "Previous week",
    "current_week": "Current week",
    "next_week": "Next week",
    "past": "Past",
    "edit_reminder": "Edit Reminder",
    "new_reminder": "New reminder",
    "rename_folder": "Rename folder",
    "no_users_found": "No users found",
    "account_settings_title": "Account settings | Pinpoint Works",
    "username_and_email": "Username and Email",
    "last_login_date": "Last Login Date",
    "number_of_sites": "Number of Sites",
    "actions": "Actions",
    "before": "Before",
    "plan_format_tooltip_1": "PNG 8 (Default) - used for the majority of Sites and will create a smaller file size. This option has a smaller colour pallet - 256 colours.",
    "plan_format_tooltip_2": "PNG 24 - to be used if the colours in the PNG 8 conversion produce an undesirable result. Uses 16 million colours.",
    "tooltip_title_empty": "Title cannot be empty",
    "pdf_preview_not_available": "PDF Preview not available",
    "select_plan_format": "Select Plan Format",
    "are_you_sure_you_want_to_delete_point": "Are you sure you want to delete this point?",
    "attachments_not_available_offline": "Attachments not available in offline mode.",
    "activity_not_available_offline": "Activity not available in offline mode.",
    "create_point_no_location": "Create Point without location",
    "create_point": "Create Point",
    "drop_here_1": "Drop",
    "drop_here_2": "here",
    "drop_here_3": "to upload",
    "delete_point": "Delete point",
    "add_assignees": "Add assignees",
    "select_assignees": "Select assignees",
    "rename_attachment": "Rename attachment",
    "attachment_name_cannot_be_empty": "Attachment name cannot be empty",
    "attachments": "Attachments",
    "drop_to_upload_1": "Drop files to upload or",
    "drop_to_upload_2": "browse",
    "upload": "Upload",
    "drag_your_1": "Drag your",
    "drag_your_2": "files",
    "drag_your_3": "&",
    "drag_your_4": "media",
    "drag_your_5": "here to upload",
    "no_uploaded_files": "No uploaded files...",
    "browse": "browse",
    "MEDIA": "MEDIA",
    "images_videos_360_photos": "Images, Videos, 360 Photos",
    "item": "item",
    "items": "items",
    "download": "Download",
    "FILES": "FILES",
    "press_enter_to_send": "Press Enter to send",
    "created_by": "Created by",
    "enter_description": "Enter description...",
    "select...": "Select...",
    "need_to_add_new_site_plan": "Need to add a new site plan?",
    "add_site_name": "Add Site name",
    "this_field_is_mandatory": "This field is mandatory",
    "no_site_plan": "No Site Plan",
    "no_site_plan_selected": "No Site Plan Selected",
    "close_cf_without_saving_confirm": "Are you sure you want to close Custom Field without saving?",
    "close_cf_without_saving_confirm_time": "Click the '+' button next to the time entered in order to save.",
    "close_description_without_saving": "Are you sure you want to close description without saving?",
    "enter_text": "Enter text...",
    "close_plan": "Close plan",
    "are_you_sure_you_want_to_close_plan_without_saving": "Are you sure you want to close plan without saving?",
    "confirm_clear_all_locations": "Are you sure you want to clear all locations?",
    "dont_show_message_again": "Don’t show this message again",
    "click_or_tap_plan_to_add_pins": "Click or tap on the Site Plan to add up to 30 pins.",
    "drag_and_drop_to_edit_pin_location": "Drag and drop to edit a pin's location.",
    "make_sure_closed_area": "Make sure you have a closed area.",
    "click_or_tap_plan_to_start_area": "Click or tap on the Site Plan to start an area.",
    "edit_area_description": "Click and hold a vertice to edit an area. Click a vertice to delete it. Click inside the area to delete the full area.",
    "delete_pin": "Delete pin",
    "complete_area_description": "Click/Tap the first pin location to complete the area. Press esc to delete an unfinished area.",
    "pin_deleted": "Pin deleted",
    "undo": "Undo",
    "360_photos": "360 photos",
    "360_photo": "360 photo",
    "moved_this_point": "Moved this point",
    "copied_this_point": "Copied this point",
    "copied_this_point_from": "Copied this point from",
    "was_cleared": "was cleared",
    "location_removed": "Location removed",
    "delete_comment": "Delete comment",
    "delete_comment_confirm": "Are you sure you want to delete this comment? This cannot be undone and there will be no record of this comment in the Activity.",
    "only_comments": "Only Comments",
    "no_comments_found_1": "No",
    "no_comments_found_2": "comments",
    "no_comments_found_3": "found",
    "create_reminder": "Create reminder",
    "collapse_point": "Collapse Point",
    "expand_point": "Expand Point",
    "open_in_site": "Open in site",
    "close_point": "Close point",
    "seen_by": "Seen by",
    "save_annotation": "Save annotation",
    "annotation_info": "Annotated images will replace original images. Annotations cannot be changed once saved.",
    "no_description": "No description...",
    "update": "Update",
    "no_additional_to_display": "No additional {{type}} to display.",
    "site_hidden_in_settings_1": "This site has been hidden in your",
    "site_hidden_in_settings_2": "Profile Settings",
    "imported_site_plan": "Imported Site Plan",
    "new_site_plan": "New Site Plan",
    "you_are_not_admin_normal": "You are not an Admin or Normal user",
    "cant_move_to_the_same_site": "You can't move points to the same site",
    "add_new_cf": "Add new Custom Field",
    "no_cfs_found": "No Custom Fields found",
    "no_pdf_logo_found": "No PDF logo found",
    "no_site_plan_found": "No site plan found",
    "no_tags_found": "No tags found",
    "resend_invitation": "Resend Invitation",
    "showing": "Showing",
    "group_by": "Group by",
    "timeframe": "Timeframe",
    "new_point": "New point",
    "table_columns": "Table Columns",
    "points_lowercase": "points",
    "point_lowercase": "point",
    "white": "White",
    "black": "Black",
    "yellow": "Yellow",
    "red": "Red",
    "blue": "Blue",
    "green": "Green",
    "copy_points_to_the": "Copy points to the",
    "move_points_to_the": "Move points to the",
    "copy_move_points_to_the_site": "site",
    "copy_size_error_1": "The total size of points to copy is",
    "copy_size_error_2": "The size limit for copying points is",
    "copy_size_error_3": "Please select fewer points to copy or choose to copy points without attachments using the tick box below. If necessary, attachments can be downloaded prior to copying.",
    "move_size_error_1": "The total size of points to move is",
    "move_size_error_2": "The size limit for moving points is",
    "move_size_error_3": "Please select fewer points to copy or choose to move points without attachments using the tick box below. If necessary, attachments can be downloaded prior to moving.",
    "copy_move_conflict_1": "The following information does not exist or the values may differ on",
    "tag_delete_warning": "These users may lose access to points if this tag is deleted.",
    "share_no_tags_error": "Please select a tag or tags to limit for this user.",
    "delete_site": "Delete site",
    "delete_site_confirm": "Yes, delete Site",
    "subscription": "Subscription",
    "options": "Options",
    "number_of_users": "Number of users",
    "site_name": "Site name",
    "upload_new_logo": "Upload new logo",
    "delete_current_logo": "Delete current logo",
    "save_changes": "Save changes",
    "white_label_clear_message": "You are about to clear all the field values. This will reset the White Label to the default values.",
    "white_label_clear_header": "Clear all",
    "prompt_white_label_clear": "The White Label has been changed to default settings.",
    "prompt_white_label_clear_error": "The White Label has not been changed.",
    "subscription_limit_info": "Your {{planName}} plan comes with the following limits. Upgrade the plan to the Per Project Subscription or change the current plan’s limits by clicking on “Change Subscription.“",
    "subscription_unlimited_info": "This is an unlimited account.",
    "subscription_limit_info__limited": "Your limited plan has the following restrictions, to upgrade your limits or move to an unlimited subscription, contact your account manager.",
    "of": "of",
    "admin_users": "Site Admin Users",
    "account_admin_users": "Account Admin Users",
    "normal_users": "Normal Users",
    "guest_users": "Guest Users",
    "change_subscription": "Change Subscription",
    "change_limits": "Change Limits",
    "subscription_update_tooltip": "Choose a Per Project subscription for unlimited users and sites on the account or pay Per User to specify the number of user types and sites needed for an account.",
    "number_of_account_admin_users": "Number of Account Admin Users",
    "number_of_admin_users": "Number of Site Admin Users",
    "number_of_normal_users": "Number of Normal Users",
    "number_of_guest_users": "Number of Guest Users",
    "delete_site_tooltip": "Removing this site will remove all users, and the site will no longer be available. You will not be able to undo this action.",
    "delete_site_points_remaining": "Please move or delete all points from this site.",
    "site_deleted": "Site has been deleted.",
    "prompt_export_users_start": "The users are now being exported. Depending on the size of the export this may take a few moments.",
    "pdf_logos": "PDF logos",
    "sites_limit_reached": "Sites limit reached",
    "users_limit_reached": "Users limit reached",
    "add_site": "Add Site",
    "sites_limit_reached_description_1": "You have reached the limit for the number of sites which can be added to your account. Please ",
    "sites_limit_reached_description_2": "about upgrading to an unlimited subscription, or adding extra sites to your limited subscription.",
    "users_limit_reached_description_1": "You have reached the limit for the number of users which can be added to your account. Please ",
    "users_limit_reached_description_2": "about upgrading to an unlimited subscription, or adding extra users to your limited subscription.",
    "prompt_changes_tags_limited": "User with limited tags can't delete all tags from a point.",
    "has_been_tagged_to": "has been tagged to",
    "table_columns_dropdown_info": "Any changes made to the Table Columns settings will be made for all users on the site.",
    "table_columns_dropdown_tooltip_1": "Select which columns of data you'd like to display in the table using the toggle on/off button.",
    "table_columns_dropdown_tooltip_2": "To rearrange the column order, click and hold the column name below to drag and drop into a different position.",
    "table_columns_dropdown_tooltip_3": "Reset the table by clicking the 'Reset Table' button at the bottom of this window.",
    "reset_table": "Reset table",
    "reset": "Reset",
    "sort": "Sort",
    "side_panel_text_color": "Text colour in side panel",
    "side_panel_text_color_hover": "Text hover colour in side panel",
    "point_selected": "Point selected",
    "points_selected": "Points selected",
    "empty": "Empty",
    "group_points_description": "Group points by up to 3 fields, then sort by one or more fields for a customized view of the points list.",
    "save_to_views": "Save to Views",
    "add_grouping_condition": "Add Group condition",
    "select_group": "Select Group",
    "deselect_group": "Deselect Group",
    "collapse_all": "Collapse all",
    "expand_all": "Expand all",
    "then_by": "Then by",
    "mark_all_as_read": "Mark all as read",
    "no_grouping_applied": "No grouping applied to this table.",
    "no_more_fields_to_choose_from": "No more fields to choose from",
    "select_a_field": "Select a field",
    "ascending": "Ascending",
    "descending": "Descending",
    "reset_columns": "Resets column widths, visibility and position to default values",
    "reset_grouping": "Resets grouping to default values",
    "reset_sorting": "Resets sorting to default values",
    "login": "Login",
    "please_enter_your_details": "Please enter your details",
    "enter_your_email": "Enter your email",
    "enter_your_password": "Enter your password",
    "reate_password_for_later": "Create a new password which you will use later.",
    "components": "Components",
    "yco_pdf_layout": "YCO PDF Layout",
    "image_width": "Image width",
    "image_height": "Image height",
    "current_site": "Current site",
    "select_a_site": "Select a site",
    "activities": "Activities",
    "photos": "Photos",
    "color_must_be_hex": "Color must be in hex format",
    "no_sorting_applied": "No sorting applied to this table",
    "sort_by": "Sort by",
    "sort_table_tooltip": "Sort the table by one or more fields of data in ascending or descending order.",
    "select_order": "Select order",
    "folder_empty": "This folder is empty",
    "toggle_collapse": "Toggle collapse",
    "toggle_unsubscribed": "toggle unsubscribed",
    "hiring": "We're Hiring",
    "future": "Future",
    "saved_views": "Saved Views",
    "prompt_saved_view_updated": "Saved View updated successfully.",
    "components_darker": "Components darker",
    "components_darker_2": "Components darker 2",
    "components_lighter": "Components lighter",
    "components_lighter_2": "Components lighter 2",
    "components_lighter_3": "Components lighter 3",
    "components_hover": "Components hover",
    "components_disabled": "Components disabled",
    "views": "Views",
    "my_views": "My Views",
    "shared_views": "Shared Views",
    "create_view": "Add view from current layout",
    "rename_view": "Rename view",
    "set_my_default_view": "Set as my default view",
    "set_shared_default_view": "Set as site default view",
    "clear_my_default_view": "Clear as my default view",
    "clear_shared_default_view": "Clear as site default view",
    "duplicate_view": "Duplicate view",
    "duplicate_view_to_shared": "Duplicate to Shared Views",
    "duplicate_view_to_personal": "Duplicate to My Views",
    "delete_view": "Delete view",
    "from_your_saved_views": "from your saved views?",
    "from_shared_views": "from shared views?",
    "no_shared_views": "No shared views have currently been made",
    "switch_to_basic_filters": "Switch to basic filters",
    "switch_to_advanced_filters": "Switch to advanced filters",
    "add_filter_condition": "Add Filter condition",
    "is": "Is",
    "is_not": "Is not",
    "is_any_of": "Is any of",
    "is_none_of": "Is none of",
    "where": "Where",
    "filter_items": "Filter items",
    "does_not_contain": "Does not contain",
    "is_empty": "Is empty",
    "is_not_empty": "Is not empty",
    "is_greater_than": "Is greater than",
    "is_less_than": "Is less than",
    "is_checked": "Is checked",
    "is_unchecked": "Is unchecked",
    "is_all_of": "Is all of",
    "my_default_view_changed": "My default view successfully changed.",
    "site_default_view_changed": "Site default view successfully changed.",
    "primary_75": "Primary 75",
    "primary_50": "Primary 50",
    "primary_100": "Primary 100",
    "primary_200": "Primary 200",
    "primary_300": "Primary 300",
    "primary_400": "Primary 400",
    "primary_500": "Primary 500",
    "primary_600": "Primary 600",
    "primary_700": "Primary 700",
    "primary_800": "Primary 800",
    "primary_900": "Primary 900",
    "is_between": "Is between",
    "is_before": "Is before",
    "is_after": "Is after",
    "start_date": "Start date",
    "end_date": "End date",
    "passwords_are_the_same": "Password must not be the same as existing!",
    "contains": "Contains",
    "save_to_current_view": "Save to current view",
    "no_filters_applied_to_table": "No filters applied to this table.",
    "filter_by": "Filter by",
    "exists": "Exists",
    "does_not_exist": "Does not exist",
    "is_exactly": "Is exactly",
    "is_a_part_of": "Is a part of",
    "advanced_filter_tooltip": "With Advanced Filters, you can narrow down your points based on a variety of fields and conditions.",
    "view_update_permission_error": "Shared view is applied and the user doesn’t have authorisation to update it",
    "no_view_applied": "No view is applied",
    "select_option": "Select option...",
    "enter_number": "Enter number...",
    "select_users": "Select users...",
    "select_date": "Select date...",
    "select_text": "Select text...",
    "from...": "From...",
    "to...": "To...",
    "select_tags...": "Select tags...",
    "user_management": "User Management",
    "restrict_to_only_see_certain_tags": "Restrict to only see certain Tags",
    "last_login": "Last Login",
    "N/A": "N/A",
    "unrestricted": "Unrestricted",
    "can_edit": "Can edit",
    "advanced_filters": "Advanced Filters",
    "remove_from_all_sites": "Remove from All Sites",
    "select_all_permissions": "Select all Permissions",
    "permissions_selected": "Permissions Selected",
    "remove": "Remove",
    "prompt_shares_delete_error": "Sorry, this request has failed. Please try again or contact support if you are unable to perform this action.",
    "prompt_user_shares_deleted": "The selected users have been removed from the selected sites.",
    "prompt_site_user_shares_deleted": "The selected users have been removed from this site.",
    "confirm_remove_users": "This action will remove the selected users from the selected sites. Are you sure you want to remove these users?",
    "user_management_user_message_one": "Showing {{count}} User",
    "user_management_user_message_other": "Showing {{count}} Users",
    "define_permissions_for_multiple_users": "Define permissions for Multiple Users",
    "cant_edit_admin_permissions": "You cannot edit permissions for an Admin",
    "no_custom_fields_exist": "No custom fields exist",
    "remove_users_from_all_sites": "Remove Users From All Sites",
    "remove_username_from_all_sites": "Remove {{username}} from All Sites",
    "remove_username_from_site_confirm": "Are you sure you want to remove {{username}} from All Sites?",
    "remove_user_from_all_sites": "Remove User From All Sites",
    "remove_users_from_sites": "Remove Users From Sites",
    "remove_users_button": "Remove Users",
    "add_users_to_sites": "Add Users To Sites",
    "selected_sites_number": "({{current}} out of {{max}} selected)",
    "search_sites": "Search Sites",
    "search_users_or_sites": "Search Users or Sites",
    "next": "Next",
    "back_to_user_&_site_selection": "Back to User & Site selection",
    "exports": "Exports",
    "note_permissions_replaces_with_new": "A default set of permissions are showed here. Previous permissions will be replaced for every site and user selected. Please ensure you have applied any custom field restrictions accordingly.",
    "point_csv_export_note": "To preserve number and custom field unit formatting, we recommend using XLSX export",
    "add_users_and_select_sites": "Add Users & Select Sites",
    "save_site_permissions": "Save Site Permissions",
    "add_users": "Add Users",
    "user_access_customized": "This users access level has been customized",
    "share_edit_email_input_tooltip": "You can input multiple emails or usernames, separated by commas.",
    "user_management_permission_changed_prompt": "Permissions have been updated for {{userName}}",
    "user_management_bulk_changes_edit_disabled_tooltip": "You can't edit permissions for multiple users and multiple sites simultaneously",
    "user_management_create_error_description_1": "Sorry, the site was not shared with any users. This might be because:",
    "user_management_create_error_description_2": "Or, they might not have accepted their previous invitation. Please check and try again.",
    "user_management_add_users_error_tooltip": "There are errors in users in this list. Please check and try again.",
    "user_management_custom_field_does_not_exist": "Custom Field does not exist on this site",
    "warning_duplicate_emails": "We've detected a duplicate, please remove the highlighted users.",
    "duplicate_emails_tooltip": "A duplicate email address or username has been detected.",
    "user_management_export_users_button": "Export Users",
    "user_management_bulk_changes_define_permissions": "Define Permissions for {{ siteName }}",
    "2fa_setup": "Set up Two-Factor Authentication",
    "2fa_enter_phone": "Choose whether you want your passcodes to be sent to you via SMS or WhatsApp. This will be how you receive all codes in the future.",
    "2fa_send_code": "Send code",
    "2fa_set_up_later": "Set up 2FA later",
    "2fa_enter_your_phone_number": "Enter your phone number",
    "2fa_resend_code_1": "Didn't receive a code?",
    "2fa_resend_code_2": "Resend",
    "countdown_timer": "({{ time }} Seconds)",
    "2fa_code_header": "Enter code",
    "2fa_code_description_1": "We've just sent a code to",
    "2fa_code_description_2": ", please check your WhatsApp account or SMS and enter it below.",
    "2fa_code_confirm": "Continue",
    "2fa_resend_prompt": "Code has been resent",
    "2fa_invalid_phone_number": "Invalid phone number",
    "2fa_code_error": "There's an issue with the verification code you entered. Please try again.",
    "2fa": "2FA",
    "2fa_disable": "Disable 2FA",
    "2fa_set_up": "Set up 2FA",
    "reset_2fa": "Remove 2FA from user",
    "prompt_2fa_reset_success": "2FA has been removed from this user.",
    "prompt_2fa_reset_error": "2FA has not been removed from this user.",
    "2fa_success_header": "Success!",
    "2fa_success_description": "You have successfully set up Two-Factor Authentication for your account. ",
    "2fa_success_continue": "Continue",
    "search_country": "Search country...",
    "invalid_country_code": "Invalid country code",
    "phone_number_too_short": "Phone number too short",
    "phone_number_too_long": "Phone number too long",
    "is_possible_local_only": "This phone number may be local only",
    "phone_number_invalid_length": "Phone number invalid length",
    "send_via_sms": "Send via SMS",
    "send_via_whatsapp": "Send via WhatsApp",
    "2fa_um_not_enabled": "This user does not have two factor authentication enabled",
    "2fa_um_enabled": "This user has two factor authentication enabled",
    "update_email": "Update email",
    "change_email": "Change email",
    "update_email_modal_text_1": "Your current email is ",
    "update_email_modal_text_2": "Please enter the new email address you wish to associate with your account.",
    "update_email_modal_text_3": "You will need to use this email address for future logins.",
    "update_email_modal_text_4": "Changing your email will log you out.",
    "update_email_modal_text_5": "You will need to verify your new email address to log back in.",
    "new_email": "New email",
    "prompt_email_change_success": "Your email has been updated successfully.",
    "prompt_email_change_error": "Failed to update email address. Please check formatting is correct and no other users are already signed up with this address.",
    "2fa_modal_header": "Verify identity to complete this change",
    "2fa_modal_confirm": "Continue",
    "add_account_admin": "Add Account Admin",
    "upgrade_to_account_admin": "Upgrade to Account Admin",
    "new_account_admin_warning": "Warning: Before assigning the Account Admin role, please ensure that you fully trust the individual with this level of access. It's recommended to limit the number of Account Admins to only those who absolutely require it for their responsibilities.",
    "new_account_admin_confirm_button": "Upgrade User",
    "new_account_admin_confirm_message": "This user will have full access to all permissions on this account, Are you sure you would you like to continue?",
    "new_account_admin_confirm_message_button": "Yes, Add Users",
    "new_account_admin_error": "There was a problem with upgrading the users",
    "new_account_admin_success": "User has been upgraded to Account Admin",
    "account_admin": "Account Admin",
    "downgrade_account_admin": "Revoke Account Admin",
    "create_new_folder": "Create new folder",
    "2fa_disabled": "Two factor authentication has been disabled",
    "cant_edit_account_admin_permission": "You cannot edit permissions for an Account Admin",
    "downgrade_account_admin_header": "Edit User Permission",
    "update_user": "Update User",
    "prompt_password_change_cancel": "Password change process cancelled; no changes made.",
    "prompt_email_change_canceled": "Email change process cancelled; no changes made.",
    "prompt_image_export": "Large - original ratio images will have aspect ratio preserved. Large images will be fitted two to a page width, Medium three to a page width, and Small four to a page width.",
    "timeline_export_duration_days": "Duration (Days)",
    "add_sorting_condition": "Add Sort condition",
    "confirm_email": "Confirm new email",
    "email_not_valid": "Email not valid",
    "emails_not_the_same": "Emails are not the same",
    "unrestrict_tag_access": "Unrestrict tag access",
    "more_options": "More options",
    "archived": "Archived",
    "account_settings_sites_footer": "Showing {{count}} Sites",
    "flagged": "Flagged",
    "unflagged": "Unflagged",
    "red_flags": "Red Flags",
    "flags": "Flags",
    "add_flag": "Add flag",
    "remove_flag": "Remove flag",
    "point_flagged": "Flagged this point.",
    "point_unflagged": "Cleared the flag for this point.",
    "is_flagged": "Is flagged",
    "is_unflagged": "Is unflagged",
    "sites_selected": "Sites selected",
    "archive": "Archive",
    "archive_site": "Archive Site",
    "archive_site_confirm": "Archiving this site means it will no longer count against any subscription limits. Users of this account, including Account Admins, will no longer be able to access it. Account Owners can restore an archived site at any time. Are you sure you wish to archive this site?",
    "unarchive": "Unarchive",
    "unarchive_site_error": "Error when trying to unarchive sites, try again later",
    "unarchive_site": "Unarchive Site",
    "unarchive_site_confirm": "Unarchiving this site means it will count against any subscription limits. Users of this account, including Account Admins, will be able to access it. Are you sure you wish to unarchive this site?",
    "unarchive_site_confirm_limit_exceeded": "Unarchiving this site will take this account over its site limit. Do you want to proceed?",
    "archived_sites_visible": "Archived sites visible",
    "archived_sites_hidden": "Archived sites hidden",
    "archive_sites_error": "Sites with active or pending integrations cannot be deleted.",
    "reset_table_tooltip": "Resets filters, sorting, grouping and columns to default values",
    "reset_timeline": "Reset timeline",
    "reset_timeline_tooltip": "Resets filters, grouping and timeframe to default values",
    "account_settings_sites_header": "({{all}} including {{archived}} archived)",
    "show_archived_sites": "Show archived sites",
    "close_select_dropdown": "Close Select Dropdown",
    "fleet_management": "Fleet Management",
    "name_fleet": "Name Fleet",
    "choose_account": "Choose an account",
    "no_assets_added_yet": "No Assets added yet",
    "add_asset": "Add Asset",
    "prompt_fleet_create_no_account_selected": "Please select an account to create a fleet for.",
    "prompt_fleet_already_created": "You have already created a fleet for this account.",
    "fleet_settings": "Fleet Settings",
    "rename_fleet": "Rename Fleet",
    "delete_fleet": "Delete Fleet",
    "delete_fleet_disabled_tooltip": "Please remove all assets from this fleet before attempting to delete it.",
    "fleet_name": "Fleet Name",
    "edit_fleet_name": "Edit Fleet name",
    "fleet_name_placeholder": "Enter Fleet name",
    "confirm_deletion_of": "Confirm deletion of {{name}}",
    "delete_fleet_message": "Delete fleet message here.",
    "delete_fleet_asset_error": "Please remove all assets from this fleet before attempting to delete it.",
    "delete_fleet_generic_error": "There was an error while trying to delete your fleet.",
    "delete_fleet_modal_body": "Are you sure you want to delete this fleet?",
    "delete_fleet_success": "Fleet successfully deleted.",
    "site_admin": "Site Admin",
    "back_to_fleet": "Back to Fleet",
    "prompt_fleet_not_found": "Fleet not found",
    "prompt_asset_body_invalid": "Invalid content in asset, try again.",
    "prompt_asset_permission_denied": "You do not have permissions to view this.",
    "prompt_asset_does_not_exist": "Error finding asset. Please try again.",
    "prompt_asset_edit_error": "Error editing asset. Please try again.",
    "prompt_asset_get_error": "Error getting asset. Please try again.",
    "prompt_asset_create_error": "Error creating asset. Please try again.",
    "prompt_assets_get_error": "Error getting assets. Please try again.",
    "prompt_asset_delete_error": "Error deleting asset. Please try again.",
    "new_asset_name_header": "Asset Name & Image",
    "new_asset_name_subheader": "Add a name and image for your asset",
    "new_asset_name_input_header": "Name",
    "new_asset_image_input_description": "Upload image",
    "new_asset_image_input_footer": "Images are best at 2:1 Aspect ratio eg. 320 x 180px",
    "new_asset_details_header": "Key Asset Details",
    "new_asset_details_subheader": "Enter key details for your Asset",
    "asset_details_beam": "BEAM",
    "asset_details_draft": "DRAFT",
    "asset_details_gross_tonnage": "GROSS TONNAGE",
    "asset_details_length": "LENGTH",
    "asset_details_build": "BUILD",
    "asset_details_imo_number": "IMO num.",
    "asset_no_labels": "No Labels are currently made for this asset",
    "asset_no_sites": "No sites are currently linked to this asset",
    "details": "Details",
    "labels": "Labels",
    "new_asset_labels_header": "Asset Labels",
    "new_asset_labels_subheader": "Create and manage labels here and define who is responsible for them",
    "color": "Color",
    "assignee": "Assignee",
    "new_asset_new_label_button": "+ Add Label",
    "new_asset_label_placeholder": "Enter label name...",
    "new_asset_label_new_assignee_button": "Assign user",
    "create_new_fleet": "Create new Fleet",
    "asset_card_tooltip_points": "Total number of points on this site, excluding any statuses filtered out at Fleet level",
    "asset_card_tooltip_budget": "Total of selected Cost CF across every point on site, excluding any statuses filtered out at Fleet level",
    "asset_card_tooltip_red_flags": "Total number of flagged points on this site",
    "asset_card_tooltip_progress1": "Overall progress of site based on the points' statuses",
    "prompt_fleet_created": "The Fleet has been created.",
    "new_asset_site_picker_header": "Connected Sites",
    "new_asset_site_picker_subheader": "For each site select which eligible field you would like to connect to the Budget overview in asset card",
    "progress": "Progress",
    "budget": "Budget",
    "points": "Points",
    "edit_asset": "Edit Asset",
    "delete_asset": "Delete Asset",
    "asset_deleted": "The Asset has been deleted",
    "delete_asset_modal_header": "Confirm deletion of {{ name }}",
    "delete_asset_modal_message": "Are you sure you want to delete this asset?",
    "delete_asset_modal_secondary_message": "Deleting this asset will remove the saved details and labels. Sites added to the asset view will remain accessible from within the sites tab in sidebar.",
    "modal_unsaved_changes_header": "Unsaved changes",
    "modal_unsaved_changes_message": "You have unsaved changes. Are you sure you want to leave?",
    "modal_unsaved_changes_message_leave_page": "You have unsaved changes. Are you sure you want to leave this page?",
    "new_asset_name_missing": "Asset name cannot be empty",
    "new_asset_name_placeholder": "Asset Name...",
    "new_asset_label_missing": "Label name cannot be empty",
    "new_asset_success": "New asset has successfully been created.",
    "edit_asset_success": "Your changes to the asset have been saved.",
    "prompt_fleet_share_unauthorized": "You are not authorised to share this fleet",
    "prompt_fleet_share_error": "There was an error while trying to share your fleet",
    "prompt_fleet_share_delete_unauthorized": "You are not authorised to delete this fleet share",
    "prompt_fleet_share_delete_error": "There was an error while trying to delete your fleet share",
    "prompt_fleet_shared": "Fleet has been shared",
    "prompt_fleet_share_deleted": "Fleet share has been deleted",
    "prompt_fleet_share_get_error": "Error getting fleet share. Please try again.",
    "share": "Share",
    "share_fleet": "Share ",
    "share_fleet_modal_description": "Invite any user to view fleet. Users invited will see all asset information, but only sites and point information they already have access to.",
    "share_fleet_modal_list_header": "Fleet shared with users",
    "share_fleet_modal_list_delete_tooltip": "Revoke view access",
    "share_fleet_modal_admin_info": "(Fleet Admin)",
    "invite_users": "Invite Users",
    "fleet_sharing_user_not_found": "User not found",
    "fleet_sharing_user_not_verified": "User not verified",
    "fleet_sharing_error_description_1": "There are issues with users you provided",
    "fleet_sharing_error_description_2": "Please check and try again",
    "fleet_sharing_email_invalid": "Email is invalid",
    "fleet_sharing_email_duplicated": "Email is duplicated",
    "fleet_sharing_email_already_exists": "Email already exists",
    "select_a_fleet": "Select a fleet",
    "fleet_sharing_confirm_close": "You have unsaved invites. Are you sure you want to leave without sending them?",
    "back": "Back",
    "leave_without_sharing": "Leave without sharing",
    "2fa_setup_sms": "Setup via SMS",
    "2fa_setup_whatsapp": "Setup via WhatsApp",
    "new_fleet_name_header": "Fleet Name & Account",
    "new_fleet_name_subheader": "Select from available accounts to create a fleet.",
    "new_fleet_account_input_header": "Account",
    "new_fleet_name_input_header": "Name",
    "new_fleet_name_input_placeholder": "Fleet name...",
    "create_fleet": "Create Fleet",
    "new_fleet_status_weight_header": "Status weighting",
    "new_fleet_status_weight_subheader": "Sites' progress calculations are based on the 'percentage complete' each status is considered to be. We provided some defaults, but you can edit them.",
    "more_info": "More info",
    "new_fleet_status_tooltip_1": "For example, if you used the weightings:",
    "new_fleet_status_tooltip_2": "Then if there were 5 points in a site marked as these statuses: 1 - Open 2 - In progress 3 - On hold 4 - Completed 5 - Cancelled That would equate to an overall progress percentage of (0 + 50 + 50 + 100 + 100) / 5 = 60%",
    "edit_fleet": "Edit Fleet",
    "update_fleet": "Save",
    "prompt_fleet_updated": "The Fleet has been updated.",
    "select_currency": "Select currency...",
    "search_currencies": "Search currencies",
    "search_fleet": "Search this fleet",
    "integration_voly_description": "Connect a Voly project to your site and keep your points up to date with invoice values dynamically. Push data about jobs back to Voly for easy tracking of work progress",
    "get_started": "Get Started",
    "integration_button_disabled_tooltip": "You do not have permission to enable an integration",
    "showing_assets_one": "Showing {{count}} Asset",
    "showing_assets_other": "Showing {{count}} Assets",
    "showing_assets": "Showing Assets",
    "new_fleet_status_filter_header": "Status Filter",
    "new_fleet_status_filter_subheader": "Filter out points with certain status types. By default all points in sites will be included in the fleet.",
    "site_is_not_authorized": "Site is not authorised",
    "authorize_connection": "Authorise Connection",
    "connection_authorized": "Connection Authorised",
    "authorization_on_voly": "Authorisation on Voly",
    "integrations_pinpoint_tooltip": "Connect a Voly project to your site and keep your points up to date with financial data like invoice values dynamically. Push data about jobs back to Voly for easy tracking of work progress.",
    "integrations_voly_tooltip": "Making this site available for integration will make your data available within the Voly platform. Completing the connection must be done from within the Voly platform, and can be done by a user who has Site Admin+ rights on this site.",
    "site_authorized_for_voly": "Site is authorised for Voly connection",
    "site_activated_for_voly_header": "Voly Integration is Active",
    "authorize_connection_timeout": "Authorisation will time out {{time}}",
    "site_authorized_for_voly_subheader": "Connection can now be completed from within your Voly Project module.",
    "site_activated_for_voly_subheader": "If you wish to configure, please go to your Voly Account",
    "cancel_authorization": "Cancel Authorisation",
    "confirm_authorization": "Confirm Authorisation",
    "confirm_authorization_description": "Making this site available for integration will make your data available within the Voly platform. Completing the connection must be done from within the Voly platform, and can be done by a user who has Site Admin+ rights on this site.",
    "confirm_authorization_description_2": "Are you sure you want to proceed?",
    "continue": "Continue",
    "fleet_top_bar_new_fleet": "Create a new Fleet",
    "fleet_top_bar_new_asset": "Create a new Asset",
    "fleet_top_bar_asset": "Edit Asset",
    "fleet_top_bar_settings": "Edit Fleet settings",
    "no_asset_search_result_header": "No assets found",
    "no_asset_search_result_subheader": "Try using different search terms or removing active filters.",
    "checkbox_filled": "Yes",
    "checkbox_empty": "No",
    "integration_status_change_error": "Error changing integration status",
    "cancel_authorization_not_allowed_tooltip": "You do not have permissions to cancel an integration",
    "integrations_voly_custom_fields_header": "Dynamic Custom Fields",
    "integrations_voly_custom_fields_subheader": "Select dynamic custom fields to add to your site. If selected for inclusion in a site, Advanced Access Levels can be configured for them via Site Settings → Users or within User Management. As new expenditure is added in Voly in different currencies, these fields will update to ensure the latest data is available to be included.",
    "integration_authentication_time_out": "Authentication has timed out today",
    "custom_field_voly_tooltip": "This field contains dynamic data from an integration with Voly",
    "disconnect_authorization_not_allowed_tooltip": "You do not have permissions to disconnect an integration",
    "disconnect_from_voly": "Disconnect from Voly",
    "integration_disconnect_error": "Error disconnecting integration",
    "integration_disconnect_success": "Integration disconnected",
    "disconnect_integration_modal_confirm": "Disconnect this site",
    "confirm_disconnect": "Confirm Disconnect",
    "disconnect_integration_modal_warning": "Disconnection will immediately end live updates between platforms.",
    "disconnect_integration_modal_description_1": "Are you sure you want to end the integration with Voly projects in Asset {{assetName}}?",
    "disconnect_integration_modal_description_2": "If you proceed, dynamic custom fields will become standard custom fields and values will no longer be kept in sync with Voly project data.",
    "disconnect_integration_modal_description_3": "Data existing in points from this integration will not be removed automatically. If you wish to remove the Voly custom fields after disconnect you can do so from within Site Settings - Manage Custom Fields.",
    "custom_field_visibility_change_error": "Error changing custom field visibility",
    "integration_status_change_success": "Integration status changed successfully",
    "custom_field_visibility_change_success": "Custom field visibility changed successfully",
    "custom_field_voly_disabled_tooltip": "This field contains dynamic data from an integration with Voly",
    "total": "Total",
    "empty_asset_dashboard_message": "No dashlets currently exist on this asset",
    "add_dashlet": "Add Dashlet",
    "integrations_voly_audit_log_header": "Audit Log",
    "integration_log_pending": "{{email}} set site as available for integration on {{time}}. Site will be available to integrate for one week until {{timeoutTime}}.",
    "integration_log_timeout": "No integration connection made after 7 days, site no longer available for integration.",
    "integration_log_connected_voly": "Site connected to Voly asset {{asset}}. Integration active.",
    "integration_log_pending_off_from_pinpoint": "{{email}} cancelled the authorisation for integration prior to completion, site no longer available for integration.",
    "integration_log_disconnected_pinpoint": "{{email}} has disconnected the integration.",
    "integration_log_disconnected_voly": "integration disconnected from within Voly Projects.",
    "tooltip_import_custom_field_integration": "Integration based fields cannot be imported",
    "flag": "Flag",
    "copy_point_integration_warning": "Data may be lost during this copy. Voly custom field values will not copy to a new site. Additionally, the new point will only contain Voly custom fields if they are available on the destination site.",
    "move_point_integration_warning": "Data may be lost during this move. Voly custom field values will not move to a new site. Additionally, the new point will only contain Voly custom fields if they are available on the destination site.",
    "voly_dynamic": "Voly Dynamic",
    "integration_read_only_tooltip": "This field is part of an integration with Voly.\nTo remove from the site please use the Integration tab.",
    "custom_field_read_only": "This custom field is read only",
    "asset_dashboard_no_site": "No sites are currently linked to this asset",
    "dashlet_settings": "Dashlet Settings",
    "multiple_sites": "Multiple Sites",
    "multiple_accounts": "Multiple Accounts",
    "showing_accounts": "Showing Accounts",
    "showing_sites": "Showing Sites",
    "dashlet_stacked_status": "Live Status per Site",
    "can_only_select_x_sites": "You can only select up to {{count}} sites",
    "dashlet_cost_comparison": "Budget",
    "cost_comparison_cost": "Cost",
    "cost_comparison_budget": "Budget",
    "add_dashlets": "Add Dashlets",
    "add_dashlet_budget": "Add Dashlet - Budget",
    "back_to_dashlets": "Back to dashlets",
    "cost_comparison_cost_description": "Choose a cost Custom Field from the site to use as a comparison against the cost Custom Field in the \"Budget\" column.",
    "cost_comparison_budget_description": "Choose a cost Custom Field from the site to represent a budget value. This will be compared with the value of the Custom Field selected in the \"Cost\" column.",
    "add_budget": "+ Add Budget",
    "select_custom_field": "Select Custom Field",
    "no_cost_fields": "No cost fields available",
    "no_budget_fields": "No budget fields available",
    "cost_comparison_add_row_disabled": "You can have up to 5 per dashlet. Please create a new budget dashlet if you need more.",
    "cost_comparison_currency_error": "Currencies must match.",
    "cost_comparison_field_missing_error": "A custom field used in this configuration has been changed or deleted. Please reconfigure",
    "dashlet_cost_comparison_tooltip": "This dashlet displays data filtered according to your fleet settings. Only data from points that meet the fleet filter criteria are included. For example, if the fleet filter excludes Completed points, data from those points will not be reflected in these figures.",
    "delete_dashlet": "Delete Dashlet",
    "cost_comparison_setup_header": "Configure this dashlet to compare costs",
    "start_setup": "Start Setup",
    "dashlet_cost_comparison_currencies_error_1": "Invalid data source. Please ",
    "dashlet_cost_comparison_currencies_error_2": "configure",
    "dashlet_cost_comparison_currencies_error_3": " this dashlet",
    "asset_workspaces_limit_info": "You can only have 5 sites per asset.",
    "invalid_data": "Invalid data",
    "update_dashlet": "Update Dashlet",
    "edit_dashlet_budget": "Configure Dashlet - Budget",
    "reconfigure": "Reconfigure",
    "voly_integration": "Voly Integration",
    "examples": "Examples",
    "formula_builder_placeholder": "Formula definition will show here.",
    "operators": "Operators",
    "max_formula_custom_fields_limit_reached": "Formula cannot contain more than four custom fields",
    "choose_formula_output_type": "Select option...",
    "output_type": "Output Type",
    "formula_description": "Formulas allow you to mathematically combine other custom fields on your site and see a dynamic result.",
    "formula": "Formula",
    "unable_to_calculate_formula": "Unable to calculate result of formula",
    "formula_result": "Result of a Formula calculation.",
    "formula_update_warning": "Updating the Formula used in this custom field will update resulting values across this site. Are you sure you wish to proceed?",
    "formula_nesting_level_error": "Formula is product of too many other formulas.",
    "formula_delete_warning_header": "Are you sure you want to delete this Formula?",
    "formula_delete_warning": "Deleting this custom field will not delete any other custom fields used to create the Formula. Values produced as a result of the Formula itself will be deleted, but values in all other custom fields will remain.",
    "formula_example_1": "Example 1: i.e to show marked-up cost",
    "formula_example_2": "Example 2: i.e to show average cost",
    "export_asset": "Export Asset",
    "export_asset_name_1": "Export ",
    "export_asset_name_2": " to XLSX",
    "include_cover_page": "Include cover page",
    "asset_export_orientation": "Page Layout",
    "asset_export_orientation_tooltip": "Portrait orientation shows two advanced dashlets per page. Landscape will show four advanced dashlets per page",
    "portrait": "Portrait",
    "landscape": "Landscape",
    "select_dashlets_to_export": "Select Dashlets to Export",
    "export_fleet": "Export Fleet",
    "integration_custom_field_invoice_tooltip": "Where expenditure is added to a job in Voly which corresponds with a point in Pinpoint Works, this data will be automatically populated in the Voly asset’s reporting currency.",
    "integration_custom_field_invoice_currency_tooltip": "This field represents expenditure in a non-reporting currency assigned to a job in Voly. Data will be automatically populated based on the values added in Voly.",
    "integration_custom_field_other_tooltip": "Premade list of connected project's {{customField}} to allow for assignation in Pinpoint Works.",
    "view_list": "View list",
    "formula_disable_integration_warning": "This field is used in formula custom field/s {{formulaCustomFieldNames}}. Please update or remove this formula in order to exclude {{volyIntegrationFieldName}} from this site.",
    "ok": "OK",
    "warning": "Warning",
    "expenditure_categories": "Expenditure Categories",
    "cost_centres": "Cost Centres",
    "add_cost": "Add Cost",
    "add_percentage": "Add Percentage",
    "add_numbers": "Add Numbers",
    "subfield_description_placeholder": "input description of value here",
    "subfield_expand": "Expand",
    "subfield_collapse": "Hide",
    "sub_field_toggle_label": "Allow {{customFieldType}} Sub-Fields in Point Card",
    "disable_subfield_warning": "Warning: All Sub-Fields and descriptions will be removed, except for the total.",
    "asset_export_format": "Page Format",
    "page_format_a4": "A4",
    "page_format_letter": "Letter",
    "prompt_bulk_changes_subfields_edit_disabled": "Custom fields with subfields cannot be bulk edited",
    "calculation_enabled": "Press = to perform basic calculations (e.g., =10 + 5)",
    "subfield": "subfield",
    "updated_to": "updated to",
    "deleted": "deleted",
    "delete_site_warning": "You are about to delete ",
    "delete_site_modal_1": "The following will happen if you delete a site:",
    "delete_site_modal_2": "The site will be removed from the sidebar.",
    "delete_site_modal_3": "All users on the site will be removed.",
    "delete_site_modal_4": "An email will be sent to all users about the site deletion.",
    "cf_import_voly_integration_error": "tooltip_import_custom_field_integration",
    "cf_import_formula_error": "Please ensure all component parts of the Formula are available on the destination site also.",
    "cf_import_name_conflict_error": "prompt_new_custom_field_taken",
    "cf_import_timeline_limit_error": "prompt_field_create_error_timeline_doubled",
    "in": "in",
    "edit_multiple": "Edit Multiple",
    "edit_multiple_shares_tooltip": "You can edit all fields by changing the fields on this row, this will overwrite any permissions currently set below.",
    "fleet": "Fleet",
    "sites_and_accounts": "Accounts & Sites",
    "search_accounts_and_sites": "Search Accounts & Sites",
    "folder_settings": "Folder Settings",
    "prompt_folder_deleted": "The folder has been deleted.",
    "prompt_folder_renamed": "The folder has been renamed.",
    "prompt_folder_created": "The folder has been created.",
    "prompt_folder_already_exists": "A folder with this name already exists.",
    "combine_selected_items": "Combine the selected items",
    "combine_selected_items_description": "Combine items to filter points that include all of the selected items. Deselect this option to filter points with any of the selected items.",
    "conditional_formula_logical_test_placeholder": "Enter logical test...",
    "conditional_formula_truthy_placeholder": "Enter value if true...",
    "conditional_formula_falsey_placeholder": "Enter value if false...",
    "conditional_formula_condition_label": "If...",
    "conditional_formula_truthy_label": "is true, set the value of this field to",
    "conditional_formula_falsy_label": "otherwise set it to:",
    "standard": "Standard",
    "conditional": "Conditional",
    "view_conditional_example": "View conditional example",
    "view_standard_example": "View standard example",
    "shipyard_quote": "Shipyard Quote",
    "estimate": "estimate",
    "prompt_comment_edited": "Comment has been edited",
    "comment_edited": "edited",
    "remove_site_from_favourites": "Remove from Favourites",
    "add_site_to_favourites": "Add to Favourites",
    "favourites": "Favourites",
    "favourites_empty": "Empty list",
    "visibility": "Visibility",
    "custom_field_visibility_tooltip": "This field controls the visibility of custom fields on point cards, exports, and site lists.",
    "cost": "Cost",
    "cost1": "Cost1",
    "new_cost": "New Cost",
    "equals_to": "Equals to",
    "not_equals_to": "Not equals to",
    "greater_than": "Greater than",
    "less_than": "Less than",
    "greater_than_or_equal": "Greater than or equal to",
    "less_than_or_equal": "Less than or equal to",
    "reach_out_to_team": "reach out to a member of the team",
    "custom_field_hidden_tooltip": "Custom Field is hidden and will not appear on point card.",
    "custom_field_visible_tooltip": "Custom Field is visible and will appear on point card.",
    "set_reminder": "Set reminder",
    "date": "Date",
    "reminder_name": "Reminder Name",
    "prompt_fetch_reminders_error": "Couldn't load reminders. Please try again.",
    "prompt_create_reminder_error": "Failed to create reminder. Please try again.",
    "prompt_edit_reminder_error": "Couldn't save changes to the reminder.",
    "prompt_delete_reminder_error": "Failed to delete reminder. Please try again.",
    "prompt_snooze_reminder_error": "Couldn't snooze the reminder. Try again.",
    "prompt_dismiss_reminder_error": "Couldn't dismiss the reminder. Try again.",
    "prompt_create_reminder_success": "Reminder set successfully.",
    "prompt_edit_reminder_success": "Changes saved to the reminder.",
    "prompt_delete_reminder_success": "Reminder deleted successfully.",
    "prompt_snooze_reminder_success": "Reminder snoozed.",
    "prompt_dismiss_reminder_success": "Reminder dismissed.",
    "prompt_dismiss_reminder_success_all": "All reminders dismissed.",
    "prompt_snooze_reminder_success_all": "All reminders snoozed.",
    "back_to_point": "Back to point",
    "empty_reminder_list": "Empty Reminder list",
    "link_to_point": "Link to point",
    "add_a_description": "Add a description",
    "at": "at",
    "today_at": "Today at:",
    "snooze": "Snooze",
    "1h": "(1h)",
    "1d": "(1d)",
    "1w": "(1w)",
    "snooze_all": "Snooze All",
    "1hour": "1 Hour",
    "1day": "1 Day",
    "1week": "1 Week",
    "dismiss_all": "Dismiss all",
    "reminders_on_point": "Reminders on point",
    "x_more": "{{count}} more",
    "continue_editing": "Continue Editing",
    "discard_changes": "Discard Changes",
    "unsaved_changes_message": "If you leave now, any unsaved changes will be lost. Do you want to continue editing or discard your changes?",
    "unsaved_changes": "You have unsaved changes.",
    "delete_reminder_confirm": "Delete Reminder?",
    "delete_reminder_message": "Click the delete button below to delete the reminder permanently.",
    "daily": "Daily",
    "weekly": "Weekly",
    "monthly": "Monthly",
    "prompt_reminder_not_found": "Cannot perform action, reminder has been deleted",
    "snoozed": "Snoozed",
    "calendar_list_results": "Filtered/Search results: ",
    "today_onwards": "Today onwards",
    "january": "January",
    "february": "February",
    "march": "March",
    "april": "April",
    "may": "May",
    "june": "June",
    "july": "July",
    "august": "August",
    "september": "September",
    "october": "October",
    "november": "November",
    "december": "December",
    "select_point": "Select point",
    "reminder_settings": "Reminder Settings",
    "reminder_alerts_settings_header": "Enable Reminder Alerts",
    "reminder_alerts_settings_subheader": "Turn reminder alerts on or off.",
    "reminder_push_notifications_settings_header": "Push Notifications",
    "reminder_push_notifications_settings_subheader": "Receive reminders alerts directly on your phone.",
    "reminder_email_settings_header": "Email Reminder",
    "reminder_email_settings_subheader": "Get reminder alerts via email.",
    "prompt_reminder_settings_change_success": "Your Reminders settings have been updated.",
    "modal_enable_reminder_setting_header": "Enable In-App Alerts?",
    "modal_enable_reminder_setting_description": "Enabling In-App alerts may cause overdue reminders to appear immediately. Do you want to proceed?",
    "calendar": "Calendar",
    "point_picker_warning_unlinked": "Unlinked reminders stay on the calendar but are removed from the point card.",
    "point_picker_warning_changed": "The reminder will move to the selected point and disappear from the current point card.",
    "search_for_reminder": "Search for Reminder",
    "point_picker_warning_added": "Reminder will link to the selected point and show on its card."
  }
}
