import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PreviousPageService } from 'src/app/core/services/previous-page.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TSidePanel } from '../../side-panel.model';
import { SidePanelService } from '../../side-panel.service';
import { SidePanelUtilsService } from '../side-panel-utils.service';

@Component({
  selector: 'pp-side-panel-back',
  templateUrl: './side-panel-back.component.html',
  styleUrls: ['./side-panel-back.component.scss'],
})
export class SidePanelBackComponent {
  @Input() ppDisabled: boolean;

  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();
  EIconPath = EIconPath;

  constructor(
    private sidePanelService: SidePanelService,
    private sidePanelUtilsService: SidePanelUtilsService,
    private activeService: ActiveService,
    private previousPageService: PreviousPageService,
    private router: Router,
  ) {}

  switchSite(): void {
    if (this.ppDisabled) {
      return;
    }

    const isRemindersPointOpened = this.previousPageService.getOpenedPointFromReminder();

    if (isRemindersPointOpened) {
      this.sidePanel.notificationsExpanded = false;
      this.sidePanelUtilsService.collapsePanel();
      this.sidePanelService.backToCalendar();

      return;
    }

    if (this.previousPageService.getPreviousUrl().startsWith('/fleet-management')) {
      this.sidePanel.notificationsExpanded = false;
      this.sidePanelUtilsService.collapsePanel();
      this.sidePanelService.backToFleet();

      return;
    }

    if (this.previousPageService.getPreviousUrl().startsWith('/reminders')) {
      this.sidePanel.notificationsExpanded = false;
      this.sidePanelUtilsService.collapsePanel();
      this.router.navigateByUrl(this.previousPageService.getPreviousUrl());
      this.previousPageService.navToPreviousUrl();
      return;
    }

    const workspaceId = this.activeService.getActiveWorkspaceId();
    const canSwitch = this.sidePanelService.canSwitchSite(workspaceId);

    if (canSwitch) {
      this.sidePanel.notificationsExpanded = false;

      this.sidePanelUtilsService.collapsePanel();
      this.sidePanelService.switchSite(workspaceId);
    }
  }
}
