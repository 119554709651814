<div class="remindersCalendarList">
  <h3 class="remindersCalendarList__title">{{ 'calendar_list_results' | ppTranslate }}</h3>

  <div #scrollContainer class="scrollable">
    @for (reminder of oldReminders; track reminder.reminderId) {
      <div class="remindersCalendarList__reminder">
        <pp-reminder-calendar-element
          [ppReminder]="reminder"
          [ppListView]="true"
          [ppDailyView]="false"
        ></pp-reminder-calendar-element>
      </div>
    }

    <p class="remindersCalendarList__textSeperator">
      {{ 'today_onwards' | ppTranslate }}
    </p>

    @for (reminder of newReminders; track reminder.reminderId) {
      <div class="remindersCalendarList__reminder">
        <pp-reminder-calendar-element
          [ppReminder]="reminder"
          [ppListView]="true"
          [ppDailyView]="false"
        ></pp-reminder-calendar-element>
      </div>
    }

    <div [style.height.px]="scrollSpacerHeight"></div>
  </div>
</div>
