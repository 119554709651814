import dayjs from 'dayjs';
import { getTimezoneOffset, getTimezoneOffsetAdjusted } from '../get-timezone-offset';

const timezoneOffset = getTimezoneOffset();
const timezoneOffsetAdjusted = getTimezoneOffsetAdjusted();

export function getTimeAsStartOfDay(date: Date): number {
  return dayjs(date).startOf('day').valueOf();
}

export function getTimeAsEndOfDay(date: Date): number {
  return dayjs(date).endOf('day').valueOf();
}

function getTimeAsMidday(date: Date): number {
  return dayjs(date).startOf('day').hour(12).valueOf();
}

/**
 * UTC Midday is used to compensate for the difference timezone
 * in the case when only the date matters.
 * @param date
 */
export function getTimeAsUtcMidday(date: Date): number {
  return getTimeAsMidday(date) - timezoneOffset;
}

export function getTimeAsUtcStartOfDay(date: Date): number {
  return getTimeAsStartOfDay(date) - timezoneOffset;
}

export function getTimeUTC(date: Date): number {
  return date.getTime() - timezoneOffsetAdjusted;
}
