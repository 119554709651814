import { Injectable } from '@angular/core';
import { catchError, Observable, of, Subject, tap } from 'rxjs';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { PromptService } from '../../components/prompt/prompt.service';
import { RemindersApiProviderService } from '../../data-providers/api-providers/reminders-api-provider/reminders-api-provider.service';
import { TranslationPipe } from '../../features/translate/translation.pipe';
import { TTranslationKey } from '../../features/translate/types';
import { getErrorMessage } from '../../helpers/database/get-error-message';
import { IntercomService } from '../../services/intercom.service';
import { ResponseErrorService } from '../errors/response-error.service';
import {
  TReminder,
  TReminderActionDTO,
  TReminderDTO,
  TReminderUpdateDTO,
} from './reminders.consts';

@Injectable({
  providedIn: 'root',
})
export class RemindersService {
  private reminders: TReminder[] = [];
  private dataFetched = false; // so we can cache fetching all reminders
  private fetchedTimestamp: number = null; // so we can cache fetching reminders for a specific timestamp

  private _remindersChanged = new Subject<TReminder[]>();
  public remindersChanged$ = this._remindersChanged.asObservable();

  constructor(
    private remindersApiProviderService: RemindersApiProviderService,
    private responseErrorService: ResponseErrorService,
    private promptService: PromptService,
    private translationPipe: TranslationPipe,
    private intercomService: IntercomService,
  ) {}

  fetchReminders(): Observable<TReminder[]> {
    if (this.reminders.length && this.dataFetched) {
      return of(this.reminders);
    }

    return this.remindersApiProviderService.getReminders().pipe(
      tap((reminders) => {
        this.dataFetched = true;
        this.reminders = reminders;
        this.emitRemindersChanged();
      }),
      catchError((error) => {
        return this.handleRemindersError(error, 'prompt_fetch_reminders_error');
      }),
    );
  }

  getReminders(): TReminder[] {
    return this.reminders;
  }

  createReminder(body: TReminderDTO): Observable<TReminder> {
    return this.remindersApiProviderService.createReminder(body).pipe(
      tap((reminder) => {
        this.reminders.push(reminder);
        this.emitRemindersChanged();

        const prompt = this.translationPipe.transform('prompt_create_reminder_success');
        this.promptService.showSuccess(prompt);
        this.intercomService.trackIntercomEvent('create_reminder');
        this.intercomService.markUserCreatedReminder();
      }),
      catchError((error) => {
        return this.handleRemindersError(error, 'prompt_create_reminder_error');
      }),
    );
  }

  getFilteredReminders(timestampUntil: number): Observable<TReminder[]> {
    if (this.fetchedTimestamp >= timestampUntil && this.reminders.length) {
      return of(
        this.reminders.filter((reminder) => reminder.timestampEpochMillis <= timestampUntil),
      );
    }

    return this.remindersApiProviderService.getFilteredReminders(timestampUntil).pipe(
      tap((reminders) => {
        this.updateReminders(reminders);
        this.fetchedTimestamp = timestampUntil;
        this.emitRemindersChanged();
      }),
      catchError((error) => {
        return this.handleRemindersError(error, 'prompt_fetch_reminders_error');
      }),
    );
  }

  fetchRemindersForPoint(pointId: string): Observable<TReminder[]> {
    return this.remindersApiProviderService.getRemindersForPoint(pointId).pipe(
      tap((reminders) => {
        this.updateReminders(reminders);
        this.emitRemindersChanged();
      }),
      catchError((error) => {
        return this.handleRemindersError(error, 'prompt_fetch_reminders_error');
      }),
    );
  }

  editReminder(reminderId: string, body: TReminderUpdateDTO): Observable<TReminder> {
    return this.remindersApiProviderService.editReminder(reminderId, body).pipe(
      tap((reminder) => {
        const reminderIndex = this.reminders.findIndex((r) => r.reminderId === reminder.reminderId);
        this.reminders[reminderIndex] = reminder;
        this.emitRemindersChanged();

        const prompt = this.translationPipe.transform('prompt_edit_reminder_success');
        this.promptService.showSuccess(prompt);
      }),
      catchError((error) => {
        return this.handleRemindersError(error, 'prompt_edit_reminder_error');
      }),
    );
  }

  deleteReminder(reminderId: string): Observable<null> {
    return this.remindersApiProviderService.deleteReminder(reminderId).pipe(
      tap(() => {
        const reminderIndex = this.reminders.findIndex((r) => r.reminderId === reminderId);
        this.reminders.splice(reminderIndex, 1);
        this.emitRemindersChanged();

        const prompt = this.translationPipe.transform('prompt_delete_reminder_success');
        this.promptService.showSuccess(prompt);
      }),
      catchError((error) => {
        return this.handleRemindersError(error, 'prompt_delete_reminder_error');
      }),
    );
  }

  private checkIfSomeRemindersGotDeleted(reminderIds: string[], responseRemindersList: string[]) {
    if (reminderIds.length !== responseRemindersList.length) {
      const errorPrompt = this.translationPipe.transform('prompt_reminder_not_found');

      this.promptService.showError(errorPrompt);
    }
  }

  snoozeReminders(body: TReminderActionDTO): Observable<TReminder[]> {
    return this.remindersApiProviderService.snoozeReminders(body).pipe(
      tap((reminders) => {
        reminders.forEach((reminder) => {
          const reminderIndex = this.reminders.findIndex(
            (r) => r.reminderId === reminder.reminderId,
          );
          this.reminders[reminderIndex] = reminder;
        });

        this.checkIfSomeRemindersGotDeleted(
          body.reminderIds,
          reminders.map((r) => r.reminderId),
        );

        this.emitRemindersChanged();

        if (reminders.length !== 0) {
          const prompt = this.translationPipe.transform('prompt_snooze_reminder_success');
          this.promptService.showSuccess(prompt);
        }
      }),
      catchError((error) => {
        return this.handleRemindersError(error, 'prompt_snooze_reminder_error');
      }),
    );
  }

  dismissReminders(body: TReminderActionDTO): Observable<TReminder[]> {
    return this.remindersApiProviderService.dismissReminders(body).pipe(
      tap((reminders) => {
        reminders.forEach((reminder) => {
          const reminderIndex = this.reminders.findIndex(
            (r) => r.reminderId === reminder.reminderId,
          );
          this.reminders[reminderIndex] = reminder;
        });

        this.checkIfSomeRemindersGotDeleted(
          body.reminderIds,
          reminders.map((r) => r.reminderId),
        );

        this.emitRemindersChanged();

        if (reminders.length !== 0) {
          const prompt = this.translationPipe.transform('prompt_dismiss_reminder_success');
          this.promptService.showSuccess(prompt);
        }
      }),
      catchError((error) => {
        return this.handleRemindersError(error, 'prompt_dismiss_reminder_error');
      }),
    );
  }

  clearData(): void {
    this.reminders = [];
    this.dataFetched = false;
    this.fetchedTimestamp = null;

    this.emitRemindersChanged();
  }

  updateRemindersFromSockets(reminders: TReminder[]): void {
    this.updateReminders(reminders);
    this.emitRemindersChanged();
  }

  updateReminders(reminders: TReminder[]): void {
    reminders.forEach((reminder) => {
      const reminderIndex = this.reminders.findIndex((r) => r.reminderId === reminder.reminderId);
      if (reminderIndex === -1) {
        this.reminders.push(reminder);
      } else {
        this.reminders[reminderIndex] = reminder;
      }
    });
  }

  private handleRemindersError(error: any, errorKey: TTranslationKey): Observable<never> {
    if (error.status === EStatusCode.BAD_REQUEST) {
      getErrorMessage(error).then((message) => {
        this.promptService.showError(message);
      });
    } else if (error.status === EStatusCode.NOT_FOUND) {
      const prompt = this.translationPipe.transform('prompt_reminder_not_found');

      this.promptService.showError(prompt);
    } else {
      const prompt = this.translationPipe.transform(errorKey);

      this.promptService.showError(prompt);
    }

    return this.responseErrorService.handleRequestError(error);
  }

  private emitRemindersChanged(): void {
    this._remindersChanged.next(this.reminders);
  }
}
