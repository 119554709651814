import { getTimeAsEndOfDay, getTimeAsStartOfDay } from 'src/app/core/helpers/date/date';
import { TPoint } from '../../../points/points.model';
import { TReminder } from '../../../reminders/reminders.consts';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterReminder } from '../../models/advanced-filter.model';

export function filterReminderAdvanced(
  point: TPoint,
  filter: TAdvancedFilterReminder,
  reminders?: TReminder[],
): boolean {
  const filterValue = filter.value?.length ? filter.value : [null, null];
  const fieldValue = reminders.filter((reminder) => reminder.pointId === point._id);

  switch (filter.option) {
    case EAdvancedFilterOptions.IS_EMPTY:
      return !fieldValue.length;
    case EAdvancedFilterOptions.IS_NOT_EMPTY:
      return !!fieldValue.length;
    default:
      break;
  }

  for (let reminder of fieldValue) {
    const fieldValueToFilter = getTimeAsStartOfDay(new Date(reminder.timestampEpochMillis));
    const filterValueToFilter = filterValue[0]
      ? getTimeAsStartOfDay(new Date(filterValue[0]))
      : null;
    let match = false;

    switch (filter.option) {
      case EAdvancedFilterOptions.IS:
        match = !filterValue[0] || fieldValueToFilter === filterValueToFilter;
        break;
      case EAdvancedFilterOptions.IS_BEFORE:
        match = !filterValue[0] || fieldValueToFilter < filterValueToFilter;
        break;
      case EAdvancedFilterOptions.IS_AFTER:
        match = !filterValue[0] || fieldValueToFilter > filterValueToFilter;
        break;
      case EAdvancedFilterOptions.IS_BETWEEN: {
        const filterValueToFilterEnd = getTimeAsEndOfDay(new Date(filterValue[1]));

        match =
          filterValue[0] &&
          filterValue[1] &&
          fieldValueToFilter > filterValueToFilter &&
          fieldValueToFilter < filterValueToFilterEnd;
        break;
      }
    }

    if (match) {
      return true;
    }
  }

  return false;
}
