import { Component, effect, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { PreviousPageService } from 'src/app/core/services/previous-page.service';
import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventReminders,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { TranslationService } from 'src/app/project/services/translation.service';
import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { PreferencesService } from '../../../preferences/preferences-service/preferences.service';
import {
  TReminder,
  TReminderActionDTO,
  TReminderDTO,
  TReminderUpdateDTO,
} from '../../../reminders/reminders.consts';
import { RemindersService } from '../../../reminders/reminders.service';
import { getReminderPriorityData } from '../../../reminders/utils/get-reminder-priority-data';
import { getReminderStatusIcon } from '../../../reminders/utils/get-reminder-status-icon';
import { TPointLightweight } from '../../points.model';
import { PointsService } from '../../points.service';
import { EPriority } from '../../priorities';
import { generateTimeText } from '../../utils/generate-time-text';
import { getTimezone } from './point-reminder-modal-time-input/point-reminder-modal-time-input-utils';
import { TReminderModalData } from './point-reminder-modal.consts';

@Component({
  selector: 'pp-point-reminder-modal',
  templateUrl: './point-reminder-modal.component.html',
  styleUrls: ['./point-reminder-modal.component.scss'],
})
export class PointReminderModalComponent implements OnInit {
  isEditing: boolean = true;
  reminder: TReminder;
  modal: Modal = this.modalService.getModal();

  nameInput = signal<string>('');
  descriptionInput = signal<string>('');
  timeInput = signal<string>(this.getNextFull15Minutes(new Date()));
  dateInput = signal<Date>(new Date());

  timeStampBackup: number;
  pointIdBackup: string;
  EIconPath = EIconPath;
  priorityName: string = '';
  reminderBackup: TReminder;

  pointId: string = '';
  pointTitle: string = '';

  statusIconSrc: EIconPath;

  date: string;
  time: string;

  isClosing: boolean = false;
  isNew: boolean = true;
  isDeleting: boolean = false;
  isEdited: boolean = false;
  isSnoozed: boolean = false;
  reminderSnoozeTime: string = '';
  isPlainReminder: boolean;

  constructor(
    private modalService: ModalService,
    private remindersService: RemindersService,
    private router: Router,
    private pointsService: PointsService,
    private preferencesService: PreferencesService,
    private translationService: TranslationService,
    private previousPageService: PreviousPageService,
  ) {
    effect(() => {
      this.update();
    });
  }

  update() {
    this.isEdited = this.changesMadeToInput();
  }

  ngOnInit(): void {
    const modalData: TReminderModalData = this.modal.data;
    this.pointId = modalData.pointId || modalData.reminder?.pointId;

    if (modalData.reminder) {
      this.pointTitle = modalData.reminder.pointTitle;
      this.isNew = false;
      this.isEditing = false;
      this.reminder = { ...modalData.reminder };
      this.pointId = modalData.reminder.pointId;
      this.isPlainReminder = !this.pointId;
      this.timeStampBackup = this.reminder.timestampEpochMillis;
      this.pointIdBackup = this.reminder.pointId;
      this.formatTimestamp();
    } else if (this.pointId) {
      const point = this.pointsService.findPoint(this.pointId);
      this.pointTitle = point.title;
      this.timeInput.set(this.getNextFull15Minutes(new Date()));
      this.dateInput.set(new Date());

      this.reminder = {
        name: '',
        description: '',
        timestampEpochMillis: this.generateTimestamp(),
        alertEpochMillis: 0,
        pointId: this.pointId,
        pointTitle: point.title,
        reminderId: '',
        targetUserId: '',
        pointPriority: point.priority,
        pointStatus: point.status,
        workspaceId: point.workspaceRef.id,
        dismissed: false,
      };
      this.formatTimestamp();
    } else {
      if (modalData.timestamp) {
        this.dateInput.set(new Date(modalData.timestamp));

        if (modalData.skipTime) {
          this.timeInput.set(this.getNextFull15Minutes(new Date()));
        } else {
          this.timeInput.set(this.getNextFull15Minutes(new Date(modalData.timestamp)));
        }
      } else {
        this.timeInput.set(this.getNextFull15Minutes(new Date()));
        this.dateInput.set(new Date());
      }

      this.reminder = {
        name: '',
        description: '',
        timestampEpochMillis: this.generateTimestamp(),
        alertEpochMillis: 0,
        pointId: this.pointId,
        pointTitle: null,
        reminderId: '',
        targetUserId: '',
        pointPriority: null,
        pointStatus: null,
        workspaceId: null,
        dismissed: false,
      };
    }

    this.isSnoozed =
      this.reminder.alertEpochMillis !== this.reminder.timestampEpochMillis &&
      !this.reminder.dismissed;

    if (this.isSnoozed) {
      const dateFormat = this.preferencesService.getPreferences().dateFormat;
      this.reminderSnoozeTime = generateTimeText(this.reminder.alertEpochMillis, dateFormat);
    }

    if (this.pointId) {
      this.generatePointData();
    }
  }

  getPriorityClass(priority: EPriority): { [key: string]: boolean } {
    return {
      lowPriority: priority === EPriority.LOW,
      mediumPriority: priority === EPriority.MEDIUM,
      highPriority: priority === EPriority.HIGH,
    };
  }

  viewPoint(): void {
    this.previousPageService.setPreviousPageOnReminderOpen(this.router.url);

    const URL = ['/site', this.reminder.workspaceId, 'point', this.reminder.pointId];
    this.router.navigate(URL);
    this.modalService.hideModal();
  }

  toggleEdit(): void {
    this.generateDateTimeForInput(new Date(this.reminder.timestampEpochMillis));
    this.nameInput.set(this.reminder.name);
    this.descriptionInput.set(this.reminder.description);
    this.pointTitle = this.reminder.pointTitle;
    this.reminderBackup = { ...this.reminder };
    this.isEditing = true;
  }

  selectPoint(point: TPointLightweight): void {
    if (point) {
      this.pointId = point.id;
      this.reminder.pointId = point.id;
      this.reminder.pointTitle = point.title;
    } else {
      this.pointId = '';
      this.reminder.pointId = '';
      this.reminder.pointTitle = '';
    }
    this.update();
  }

  submit(): void {
    const timestamp = this.generateTimestamp();

    if (this.reminder?.reminderId) {
      const editedReminder: TReminderUpdateDTO = {
        name: this.nameInput(),
        description: this.descriptionInput(),
      };

      if (timestamp !== this.timeStampBackup) {
        editedReminder.timestampEpochMillis = timestamp;
      }

      if (this.reminder.pointId !== this.pointIdBackup) {
        editedReminder.pointId = this.reminder.pointId;

        if (!editedReminder.pointId) {
          editedReminder.pointId = '';
        }
      }

      this.remindersService.editReminder(this.reminder.reminderId, editedReminder).subscribe();
    } else {
      logEventInGTAG(EGoogleEventReminders.REMINDERS_ADD_REMINDER, {
        event_category: EGoogleEventCategory.REMINDERS,
      });

      const newReminder: TReminderDTO = {
        name: this.nameInput(),
        description: this.descriptionInput(),
        timestampEpochMillis: timestamp,
        pointId: this.pointId,
      };

      this.remindersService.createReminder(newReminder).subscribe();
    }

    this.modalService.hideModal();
  }

  cancel(): void {
    if (!this.changesMadeToInput()) {
      if (this.isNew) {
        this.modalService.hideModal();
      } else {
        this.discardChanges();
      }
    } else {
      this.toggleIsClosing();
    }
  }

  discardChanges(): void {
    if (this.isNew) {
      this.close();
      return;
    }

    this.isEditing = false;
    this.isClosing = false;
    this.reminder = { ...this.reminderBackup };
  }

  toggleIsClosing(): void {
    if (this.isClosing) {
      this.isClosing = false;
      this.isDeleting = false;
      this.isClosing = false;
    } else {
      this.isClosing = true;
    }
  }

  generateDateTimeForInput(date: Date): void {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    this.timeInput.set(`${hours}:${minutes}`);
    this.dateInput.set(date);
  }

  setDateInput(date: Date[]): void {
    this.dateInput.set(date[0]);
  }

  generateTimestamp(): number {
    const date = new Date(this.dateInput());

    if (this.timeInput() !== null) {
      const [hours, minutes] = this.timeInput().split(':').map(Number);
      date.setHours(hours, minutes, 0, 0);
    }

    return date.getTime();
  }

  dismiss(): void {
    const body: TReminderActionDTO = {
      reminderIds: [this.reminder.reminderId],
    };

    this.remindersService
      .dismissReminders(body)
      .pipe(
        tap(() => {
          this.close();
        }),
      )
      .subscribe();
  }

  delete(): void {
    this.isDeleting = true;
    this.toggleIsClosing();
  }

  changesMadeToInput(): boolean {
    if (
      this.reminder.name !== this.nameInput() ||
      this.reminder.description !== this.descriptionInput() ||
      this.reminder.timestampEpochMillis !== this.generateTimestamp() ||
      this.reminder.pointId !== this.pointIdBackup
    ) {
      return true;
    }
    return false;
  }

  deleteConfirm(): void {
    this.remindersService.deleteReminder(this.reminder.reminderId).subscribe(() => this.close());
  }

  close(): void {
    this.modalService.hideModal();
  }

  formatTimestamp(): void {
    const date = new Date(this.reminder.timestampEpochMillis);
    const preferences = this.preferencesService.getPreferences();
    const dateFormat = preferences.dateFormat;

    this.date = date.toLocaleDateString();
    this.time =
      date.toLocaleTimeString(this.translationService.getLocale(), {
        hour: '2-digit',
        minute: '2-digit',
        hour12: dateFormat === EDateFormat.US || dateFormat === EDateFormat.FRIENDLY,
      }) +
      ' ' +
      getTimezone();
  }

  private generatePointData(): void {
    this.statusIconSrc = getReminderStatusIcon(this.reminder.pointStatus);

    const priorityData = getReminderPriorityData(this.reminder.pointPriority);
    this.priorityName = priorityData.priorityText;
  }

  private getNextFull15Minutes(date: Date) {
    let minutes = date.getMinutes();
    let extraMinutes = minutes === 0 ? 0 : 15 - (minutes % 15);
    date.setMinutes(minutes + extraMinutes);
    date.setSeconds(0);
    date.setMilliseconds(0);

    // Format HH:MM
    const hours = String(date.getHours()).padStart(2, '0');
    const mins = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${mins}`;
  }
}
