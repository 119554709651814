import { Component, input, output } from '@angular/core';
import { uuid } from '@core/helpers';
import { TDropdownParams } from 'src/app/project/components/dropdown/dropdown-service/dropdown-service.consts';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TReminder } from 'src/app/project/modules/reminders/reminders.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TPointLightweight } from '../../../points.model';
import { PointReminderPointPickerDropdownComponent } from './point-reminder-point-picker-dropdown/point-reminder-point-picker-dropdown.component';

@Component({
  selector: 'pp-point-reminder-point-picker',
  templateUrl: './point-reminder-point-picker.component.html',
  styleUrl: './point-reminder-point-picker.component.scss',
})
export class PointReminderPointPickerComponent {
  ppReminder = input<TReminder>();
  ppPointChange = output<TPointLightweight>();
  ppNew = input<boolean>();
  ppPointIdBackup = input('', {
    transform: transformBackupPointId,
  });

  EIconPath = EIconPath;
  buttonId = uuid();

  point: {
    name: string;
    id: string;
  };

  dropdown = this.dropdownService.getDropdown();

  constructor(private dropdownService: DropdownService) {}

  ngOnInit(): void {
    this.point = {
      name: this.ppReminder().pointTitle,
      id: this.ppReminder().pointId || '',
    };
  }

  toggleDropdown(): void {
    const modalData = {
      pointId: this.ppReminder().pointId,
    };

    const params: TDropdownParams = {
      isModalDropdown: true,
      callback: (point: TPointLightweight) => {
        this.point = {
          name: point.title,
          id: point.id,
        };

        this.ppPointChange.emit(point);
      },
    };

    this.dropdownService.toggleDropdown(
      this.buttonId,
      modalData,
      PointReminderPointPickerDropdownComponent,
      params,
    );
  }

  removePoint(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.dropdownService.hideDropdown();

    this.point = {
      name: '',
      id: '',
    };

    this.ppPointChange.emit(null);
  }
}

function transformBackupPointId(pointId: string): string {
  if (!pointId) {
    return '';
  }

  return pointId;
}
