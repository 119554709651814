import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { uuid } from '@core/helpers';
import { CalendarView } from 'angular-calendar';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import flatpickr from 'flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { Instance } from 'flatpickr/dist/types/instance';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { TTranslationKey } from 'src/app/project/features/translate/types';
import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { PointReminderModalComponent } from '../../../points/point-reminders/point-reminder-modal/point-reminder-modal.component';
import { DEFAULT_DATE_FORMAT } from '../../../preferences/default-date-format';
import { PreferencesService } from '../../../preferences/preferences-service/preferences.service';
import { RemindersCalendarService } from '../reminders-calendar.service';
import { RemindersFilterService } from '../../reminders-filter.service';
import { SitePointFilterService } from '../../../filters/site-point-filter.service';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

@Component({
  selector: 'pp-reminder-calendar-control-buttons',
  templateUrl: './reminder-calendar-control-buttons.component.html',
  styleUrl: './reminder-calendar-control-buttons.component.scss',
})
export class ReminderCalendarControlButtonsComponent {
  @Input() ppView: CalendarView;
  @Output() ppViewChange = new EventEmitter<CalendarView>();
  @Input() ppViewDate: Date;
  @Output() ppViewDateChange = new EventEmitter<Date>();
  @Input() ppLocale: string = 'en';
  @Input() ppDataFetched = false;
  @ViewChild('periodDropdown', { static: true }) intervalPicker: ElementRef;

  CalendarView = CalendarView;
  EIconPath = EIconPath;
  datepicker: Instance;
  format: string;

  modes: {
    mode: CalendarView;
    translationKey: TTranslationKey;
  }[] = [
    { mode: CalendarView.Day, translationKey: 'daily' },
    { mode: CalendarView.Week, translationKey: 'weekly' },
    { mode: CalendarView.Month, translationKey: 'monthly' },
  ];
  dropdown: TDropdown = this.dropdownService.getDropdown();
  buttonId = uuid();

  constructor(
    private dropdownService: DropdownService,
    private preferencesService: PreferencesService,
    private remindersCalendarService: RemindersCalendarService,
    private modalService: ModalService,
    private remindersFilterService: RemindersFilterService,
    private sitePointFilterService: SitePointFilterService,
  ) {
    this.setDateFormat();
  }

  ngOnChanges(): void {
    if (!this.intervalPicker) {
      return;
    }

    this.setDropdownData();
  }

  private setDropdownData(): void {
    switch (this.ppView) {
      case CalendarView.Day:
        this.destroyDatepicker();
        this.setDayDatepicker();
        break;
      case CalendarView.Week:
        this.destroyDatepicker();
        this.setWeekDatepicker();

        break;
      case CalendarView.Month:
        this.destroyDatepicker();
        this.setMonthDatepicker();
        break;
    }
  }

  private destroyDatepicker(): void {
    if (this.datepicker) {
      this.datepicker.destroy();
      this.datepicker = null;
    }
  }

  ngAfterViewInit(): void {
    this.setDropdownData();
  }

  changeMode(mode: CalendarView): void {
    this.remindersCalendarService.changeMode(mode);
    this.ppViewChange.emit();
  }

  setReminder(): void {
    this.modalService.setData({ pointId: null });
    this.modalService.showModal(PointReminderModalComponent);
  }

  setKeyword(keyword: string): void {
    this.remindersFilterService.setSearchTerm(keyword);
    this.sitePointFilterService.filterPoints();
  }

  private setMonthDatepicker(): void {
    this.datepicker = flatpickr(this.intervalPicker.nativeElement, {
      plugins: [
        monthSelectPlugin({
          shorthand: true, //defaults to false
          dateFormat: 'm.y', //defaults to "F Y"
          altFormat: 'F Y', //defaults to "F Y"
        }),
      ],
      onMonthChange: (selectedDates, dateStr, instance) => {
        const newDate = new Date(instance.currentYear, instance.currentMonth);
        instance.close();

        this.ppViewDate = newDate;
        this.ppViewDateChange.emit(newDate);
      },
    });

    this.datepicker.setDate(this.ppViewDate);
  }

  private setWeekDatepicker(): void {
    this.datepicker = flatpickr(this.intervalPicker.nativeElement, {
      dateFormat: this.generateDisplayFormat(),
      mode: 'range',
      weekNumbers: true,
      locale: {
        firstDayOfWeek: 1,
      },
      onChange: (selectedDates, dateStr, instance) => {
        const startDate = selectedDates[0];
        const startOfWeek = dayjs(startDate).startOf('week').valueOf();

        const newDate = new Date(startOfWeek);

        instance.close();

        this.ppViewDate = newDate;
        this.ppViewDateChange.emit(newDate);
      },
    });

    const startDate = dayjs(this.ppViewDate).startOf('week').valueOf();
    const endDate = dayjs(this.ppViewDate).endOf('week').valueOf();

    this.datepicker.setDate([startDate, endDate]);
  }

  private setDayDatepicker(): void {
    this.datepicker = flatpickr(this.intervalPicker.nativeElement, {
      mode: 'single',
      weekNumbers: true,
      dateFormat: this.generateDisplayFormat(),
      locale: {
        firstDayOfWeek: 1,
      },

      onChange: (selectedDates, dateStr, instance) => {
        const startDate = selectedDates[0];

        const newDate = new Date(startDate);

        instance.close();

        this.ppViewDate = newDate;
        this.ppViewDateChange.emit(newDate);
      },
    });

    this.datepicker.setDate(this.ppViewDate);
  }

  private generateDisplayFormat(): string {
    switch (this.format) {
      case EDateFormat.ISO:
        return 'Y-m-d';
      case EDateFormat.US:
        return 'm/d/Y';
      case EDateFormat.FRIENDLY:
        return 'd M Y';
      case EDateFormat.EUROPEAN:
        return 'd/m/Y';
      default:
        return 'Y-m-d';
    }
  }

  private setDateFormat(): void {
    const preferences = this.preferencesService.getPreferences();

    this.format = preferences?.dateFormat ? preferences.dateFormat : DEFAULT_DATE_FORMAT;
  }
}
