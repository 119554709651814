import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { EAdvancedFilterType } from '../../models/advanced-filter-type.enum';
import {
  TAdvancedFilterCost,
  TAdvancedFilterNumber,
  TAdvancedFilterSequenceNumber,
} from '../../models/advanced-filter.model';

export function filterNumberAdvanced(
  point: TPoint,
  filter: TAdvancedFilterNumber | TAdvancedFilterCost | TAdvancedFilterSequenceNumber,
): boolean {
  const filterValue = filter.value ? filter.value : 0;

  let value: number;

  switch (filter.type) {
    case EAdvancedFilterType.SEQUENCE_NUMBER:
      value = +point.sequenceNumber;
      break;
    case EAdvancedFilterType.COST:
    case EAdvancedFilterType.NUMBER:
      value = point.customFieldsMap?.[filter.id]?.value;
      break;
    case EAdvancedFilterType.SEQUENCE_NUMBER:
      value = +point.sequenceNumber;

      break;
  }
  const fieldValue = value ? value : 0;

  switch (filter.option) {
    case EAdvancedFilterOptions.IS:
      return +fieldValue === +filterValue;
    case EAdvancedFilterOptions.IS_NOT:
      return +fieldValue !== +filterValue;
    case EAdvancedFilterOptions.IS_GREATER_THAN:
      return +fieldValue > +filterValue;
    case EAdvancedFilterOptions.IS_LESS_THAN:
      return +fieldValue < +filterValue;
    case EAdvancedFilterOptions.IS_EMPTY:
      return !fieldValue;
    case EAdvancedFilterOptions.IS_NOT_EMPTY:
      return !!fieldValue;
  }
}
