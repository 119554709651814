<div class="pointPicker" (click)="toggleDropdown()" [id]="buttonId">
  <div class="pointPickerInput">
    <p *ngIf="!point?.name" class="pointPickerPlaceholder">{{ 'select_point' | ppTranslate }}...</p>
    <p *ngIf="point?.name" class="pointPickerPoint">{{ point.name }}</p>

    <section class="pointPicker__buttons">
      <pp-icon
        [ppSrc]="EIconPath.ICON_NAV_CLOSE_18"
        ppColor="grey-400"
        ppClass="setSize18"
        class="pointArrow"
        (click)="removePoint($event)"
        *ngIf="point?.name"
      ></pp-icon>

      <pp-icon
        [ppSrc]="EIconPath.ICON_NAV_ARROW_DOWN_18"
        ppColor="grey-400"
        ppClass="setSize18"
        class="pointArrow"
      ></pp-icon>
    </section>
  </div>
</div>

<section class="pointPickerWarning" *ngIf="ppPointIdBackup() !== point?.id && !ppNew()">
  <pp-icon
    [ppSrc]="EIconPath.ICON_BADGE_WARNING_14"
    ppColor="inherit"
    ppClass="setSize14"
  ></pp-icon>

  <p class="pointPickerWarning__text">
    {{
      (point?.id
        ? ppPointIdBackup()
          ? 'point_picker_warning_changed'
          : 'point_picker_warning_added'
        : 'point_picker_warning_unlinked'
      ) | ppTranslate
    }}
  </p>
</section>
