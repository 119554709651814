import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TFilters } from '../../site-filter.model';
import { getTimeAsStartOfDay, getTimeAsUtcMidday } from '../../../../../core/helpers/date/date';

@Component({
  selector: 'pp-filter-field-reminders',
  templateUrl: './filter-field-reminders.component.html',
  styleUrls: ['../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldRemindersComponent {
  @Input() ppFilters: TFilters;
  @Output() ppApply = new EventEmitter();

  constructor() {}

  clearReminders(): void {
    this.ppFilters.reminders.startDate = null;
    this.ppFilters.reminders.endDate = null;

    this.applyFilters();
  }

  updateRemindersStartDate(selectedDates: Date[]): void {
    this.ppFilters.reminders.startDate = getTimeAsStartOfDay(selectedDates[0]);
    this.applyFilters();
  }

  updateRemindersEndDate(selectedDates: Date[]): void {
    this.ppFilters.reminders.endDate = getTimeAsUtcMidday(selectedDates[0]);

    this.applyFilters();
  }

  applyFilters(): void {
    this.ppApply.emit();
  }
}
