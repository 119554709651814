import { TReminder } from '../../reminders/reminders.consts';
import { TFilters } from '../site-filter.model';

export function filterReminders(
  filters: TFilters,
  reminders: TReminder[],
  searchTerm: string,
): TReminder[] {
  const startDate = filters.reminders?.startDate;
  const endDate = filters.reminders?.endDate;

  searchTerm = searchTerm.toLowerCase();

  let remindersFiltered: TReminder[] = reminders;

  if (!filters.reminders && !searchTerm) return reminders;

  if (filters.reminders?.startDate || filters.reminders?.endDate) {
    remindersFiltered = remindersFiltered.filter((reminder) => {
      const reminderDate = reminder.timestampEpochMillis;
      const afterStartDate = !startDate || reminderDate >= startDate;
      const beforeEndDate = !endDate || reminderDate <= endDate;

      return afterStartDate && beforeEndDate;
    });
  }

  if (searchTerm) {
    remindersFiltered = remindersFiltered.filter((reminder) => {
      return (
        reminder.name.toLowerCase().includes(searchTerm) ||
        reminder.description.toLowerCase().includes(searchTerm) ||
        reminder.pointTitle?.toLowerCase().includes(searchTerm)
      );
    });
  }

  return remindersFiltered;
}
