import { Injectable } from '@angular/core';
import { PointsApiProviderService } from '@core/api';
import { catchError, Observable, of, tap } from 'rxjs';
import { ResponseErrorService } from '../errors/response-error.service';
import { TPointLightweight } from './points.model';

@Injectable({
  providedIn: 'root',
})
export class PointsLightweightService {
  points: {
    [wordspaceId: string]: TPointLightweight[];
  } = {};

  processing: {
    [workspaceId: string]: Observable<TPointLightweight[]>;
  } = {};

  constructor(
    private pointsApiProviderService: PointsApiProviderService,
    private responseErrorService: ResponseErrorService,
  ) {}

  fetchPoints(workspaceId: string): Observable<TPointLightweight[]> {
    if (this.points[workspaceId] && this.points[workspaceId].length) {
      return of(this.points[workspaceId]);
    }

    if (this.processing[workspaceId]) {
      return this.processing[workspaceId];
    }

    this.processing[workspaceId] = this.pointsApiProviderService
      .fetchPointsLightweight(workspaceId)
      .pipe(
        tap((points) => {
          this.points[workspaceId] = points;
          this.processing[workspaceId] = null;
        }),
        catchError((error) => this.responseErrorService.handleRequestError(error)),
      );

    return this.processing[workspaceId];
  }

  clearPoints(): void {
    this.points = {};
    this.processing = {};
  }
}
