import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@core/http';
import dayjs from 'dayjs';
import { Subject, takeUntil } from 'rxjs';
import { getSvg } from 'src/app/core/helpers/get-svg';
import { PreviousPageService } from 'src/app/core/services/previous-page.service';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { SitePointFilterService } from '../../../filters/site-point-filter.service';
import { PointReminderModalComponent } from '../../../points/point-reminders/point-reminder-modal/point-reminder-modal.component';
import { TReminderModalData } from '../../../points/point-reminders/point-reminder-modal/point-reminder-modal.consts';
import { TPoint } from '../../../points/points.model';
import { EPriority } from '../../../points/priorities';
import { PreferencesService } from '../../../preferences/preferences-service/preferences.service';
import { SiteTableEventsService } from '../../../site/site-table/site-table-events.service';
import { TReminder } from '../../reminders.consts';
import { getReminderDateTime } from '../../utils/get-reminder-date-time';
import { getReminderPriorityData } from '../../utils/get-reminder-priority-data';
import { getReminderStatusIcon } from '../../utils/get-reminder-status-icon';
import { SiteFilterDataService } from '../../../filters/site-filter-data-service/site-filter-data.service';

class reminderOptions {
  onShow: (instance: any) => void;
  siteTableEventsService: SiteTableEventsService;
}

@Component({
  selector: 'pp-reminder-calendar-element',
  templateUrl: './reminder-calendar-element.component.html',
  styleUrl: './reminder-calendar-element.component.scss',
})
export class ReminderCalendarElementComponent implements OnDestroy {
  @Input() ppReminder: TReminder;
  @Input() ppDailyView: boolean;
  @Input() ppListView: boolean;

  private destroy$ = new Subject<void>();

  EPriority = EPriority;
  formattedTimestamp: string;
  snoozedTimestamp: string;
  pointTooltip: string;
  isSnoozed: boolean;
  isDismissed: boolean;
  isOverdue: boolean;
  EIconPath = EIconPath;
  tooltipOptions: reminderOptions;
  visible: boolean = true;
  points: TPoint[] = [];

  constructor(
    private preferencesService: PreferencesService,
    private modalService: ModalService,
    private siteTableEventsService: SiteTableEventsService,
    private apiService: ApiService,
    private sitePointFilterService: SitePointFilterService,
    private previousPageService: PreviousPageService,
    private router: Router,
    private siteFilterDataService: SiteFilterDataService,
  ) {
    this.sitePointFilterService.pointsFiltered$
      .pipe(takeUntil(this.destroy$))
      .subscribe((points) => {
        this.points = points;
        this.visible = this.checkFilters(
          this.sitePointFilterService.getFilteredReminders(),
          this.sitePointFilterService.getFilteredPoints(),
          points,
        );
      });
  }

  ngOnChanges(): void {
    this.formattedTimestamp = this.setTimestamp(+this.ppReminder.timestampEpochMillis);
    this.setPointTooltip();
    this.isDismissed = this.ppReminder.dismissed;
    this.isSnoozed =
      this.ppReminder.alertEpochMillis !== this.ppReminder.timestampEpochMillis &&
      !this.isDismissed;
    this.snoozedTimestamp = this.setSnoozedTimestamp(+this.ppReminder.alertEpochMillis);
    this.isOverdue = this.ppReminder.timestampEpochMillis < Date.now() && !this.isDismissed;

    this.visible = this.checkFilters(
      this.sitePointFilterService.getFilteredReminders(),
      this.sitePointFilterService.getFilteredPoints(),
      this.points,
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private checkFilters(
    filteredReminders: TReminder[],
    filteredPoints: TPoint[],
    points: TPoint[],
  ): boolean {
    return this.checkPointFilter(filteredPoints) && this.checkReminderFilter(filteredReminders);
  }

  private checkReminderFilter(filteredReminders: TReminder[]): boolean {
    return filteredReminders.some((reminder) => reminder.reminderId === this.ppReminder.reminderId);
  }

  private checkPointFilter(filteredPoints: TPoint[]): boolean {
    const filters = this.siteFilterDataService.getFilters();
    let numberOfFilters = filters.numberOfFilters;

    if (!this.ppReminder.pointId) {
      if (filters.reminders.startDate || filters.reminders.endDate) {
        numberOfFilters -= 1;
      }

      if (numberOfFilters > 0) {
        return false;
      } else {
        return true;
      }
    }

    return filteredPoints.some((point) => point._id === this.ppReminder.pointId);
  }

  private setTimestamp(timeEpoch: number): string {
    const format = this.preferencesService.getPreferences().dateFormat;

    if (this.ppListView) {
      return getReminderDateTime(timeEpoch, format);
    }

    let inputHourFormat = ' [at] hh:mm A';

    if (format === EDateFormat.EUROPEAN || format === EDateFormat.ISO) {
      inputHourFormat = ' [at] HH:mm';
    }

    const dayJSValue = dayjs(timeEpoch);
    return dayJSValue.format(inputHourFormat);
  }

  private setSnoozedTimestamp(timeEpoch: number): string {
    const format = this.preferencesService.getPreferences().dateFormat;

    return getReminderDateTime(timeEpoch, format);
  }

  openReminder(event: MouseEvent): void {
    event.stopImmediatePropagation();

    const modalData: TReminderModalData = {
      reminder: this.ppReminder,
    };

    this.modalService.setData(modalData);
    this.modalService.showModal(PointReminderModalComponent);
  }

  openPoint(): void {
    this.previousPageService.setPreviousRemindersUrl(this.router.url);

    this.siteTableEventsService.openPointFromNotification(
      this.ppReminder.workspaceId,
      this.ppReminder.pointId,
    );
  }

  private setPointTooltip(): void {
    const statusIcon = getReminderStatusIcon(this.ppReminder.pointStatus);
    const priorityData = getReminderPriorityData(this.ppReminder.pointPriority);

    if (!priorityData) {
      this.pointTooltip = '';
      return;
    }

    const priorityText = priorityData.priorityText;

    getSvg(this.apiService, statusIcon).then((svg) => {
      this.pointTooltip = `
      <div class="reminderPointTooltip">
        <div class="reminderPointTooltip__details">
          <div class="reminderPointTooltip__priority ${this.getPriorityClass(this.ppReminder.pointPriority)}" >${priorityText}</div>
          <div class="reminderPointTooltip__status">
            ${svg}
          </div>
        </div>
        <span class="reminderPointTooltip__title">${this.ppReminder.pointTitle}</span>
      </div>`;
    });

    this.tooltipOptions = {
      siteTableEventsService: this.siteTableEventsService,
      onShow: (instance) => {
        const clickableElement = instance.popper.querySelector('.reminderPointTooltip__title');
        if (clickableElement) {
          clickableElement.addEventListener('click', () => {
            this.siteTableEventsService.openPointFromNotification(
              this.ppReminder.workspaceId,
              this.ppReminder.pointId,
            );
          });
        }
      },
    };
  }

  private getPriorityClass(priority: EPriority): string {
    switch (priority) {
      case EPriority.HIGH:
        return 'reminderPointTooltip__priority--high';
      case EPriority.MEDIUM:
        return 'reminderPointTooltip__priority--medium';
      case EPriority.LOW:
        return 'reminderPointTooltip__priority--low';
      default:
        return '';
    }
  }
}
