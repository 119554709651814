<div class="pointPickerDropdown">
  <pp-search-box
    [ppPlaceholder]="'search_sites' | ppTranslate"
    (ppAction)="setKeyword($event)"
    [ppFocusOnInit]="true"
    ppSize="wide"
    class="dropdown__searchBar"
    ppColor="#667085"
  ></pp-search-box>

  <section class="pointPickerDropdown__list" *ngIf="!pickedWorkspace">
    @for (account of filteredAccounts; track account.id) {
      <div class="pointPickerDropdown__account" (click)="toggleAccount(account.id)">
        <div class="pointPickerDropdown__item">
          {{ account.name }}
        </div>
      </div>

      @if (!collapsedAccounts[account.id]) {
        @for (workspace of account.workspaces; track workspace.id) {
          <div class="pointPickerDropdown__workspace" (click)="openWorkspace(workspace.id)">
            <div class="pointPickerDropdown__item">
              {{ workspace.name }}
            </div>
          </div>
        }
      }
    }
  </section>

  <section class="pointPickerDropdown__list" *ngIf="pickedWorkspace">
    <button class="pointPicker__backButton" (click)="back()">
      <pp-icon
        [ppSrc]="EIconPath.ICON_LEFT_MENU_ARROW_BACK_14"
        ppColor="inherit"
        ppClass="setSize14"
      ></pp-icon>
      <p class="dashletHead__backButton--text">
        {{ 'back' | ppTranslate }}
      </p>
    </button>

    <div class="pointPickerDropdown__workspace">
      <div class="pointPickerDropdown__item">
        {{ pickedWorkspace.name }}
      </div>
    </div>

    <app-spinner *ngIf="processing"></app-spinner>

    @for (point of filteredPoints; track point.id) {
      <div class="pointPickerDropdown__account" (click)="selectPoint(point)">
        <div class="pointPickerDropdown__item">{{ point.sequenceNumber }}. {{ point.title }}</div>
      </div>
    }
  </section>
</div>
