import { cloneDeep } from 'lodash';

import { Injectable, OnDestroy, Signal, signal } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TUISettings } from '../ui/ui.model';

import { TAllFilters, TFilters } from '../filters/site-filter.model';
import { TWorkspacesById } from '../workspace/workspace.model';

import { AccountService } from '../account/account-service/account.service';
import { SiteOverviewService } from '../site/site-overview/site-overview.service';
import { WorkspaceService } from '../workspace/workspace.service';

import { EStore } from '../../shared/enums/store.enum';
import { createSitesFilter } from '../site/site-overview/utils/create-sites-filter';
import { EWorkspaces } from '../workspace/workspaces.enum';

@Injectable({
  providedIn: 'root',
})
export class RemindersFilterService implements OnDestroy {
  private filters: TFilters;
  private allFilters: TAllFilters;
  private siteFilters$ = new Observable<TAllFilters>();

  private workspaces$ = new Observable<TWorkspacesById>();
  private readonly destroy$ = new Subject<void>();
  searchTerm = signal<string>('');

  constructor(
    private store: Store<{
      ui: TUISettings;
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private siteOverviewService: SiteOverviewService,
    private accountService: AccountService,
    private workspaceService: WorkspaceService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.filters = this.allFilters?.[EWorkspaces.REMINDERS];
    });

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = allFilters;
      this.filters = this.allFilters?.[EWorkspaces.REMINDERS];
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  updateRemindersFilter(): void {
    let filters = cloneDeep(this.filters);

    filters = this.addRemindersFilters(filters);
    this.siteOverviewService.updateCustomFieldFilters(filters, true);
  }

  setSearchTerm(term: string) {
    this.searchTerm.set(term);
    this.updateRemindersFilter();
  }

  getSearchTerm(): string {
    return this.searchTerm();
  }

  getSearchTermSignal(): Signal<string> {
    return this.searchTerm;
  }

  private addRemindersFilters(filters: TFilters): TFilters {
    const accounts = this.accountService.getAccounts();

    createSitesFilter(accounts, filters, this.workspaceService.getWorkspaces());

    filters.sitesDefault = this.isSitesDefault(filters);

    return filters;
  }

  isSitesDefault(filters: TFilters): boolean {
    const appliedAccounts = filters.sites.filter((account) => account.value);

    if (appliedAccounts.length !== filters.sites.length) {
      return false;
    } else {
      for (let i = 0; i < filters.sites.length; i++) {
        const site = filters.sites[i];

        const appliedSites = site.workspaces.filter((filteredSite) => filteredSite.value);

        if (appliedSites.length !== site.workspaces.length) {
          return false;
        }
      }
    }

    return true;
  }
}
