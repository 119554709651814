import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarView } from 'angular-calendar';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PointReminderModalComponent } from 'src/app/project/modules/points/point-reminders/point-reminder-modal/point-reminder-modal.component';
import { TReminderModalData } from 'src/app/project/modules/points/point-reminders/point-reminder-modal/point-reminder-modal.consts';
import { TReminder } from '../../../reminders.consts';
import { RemindersCalendarService } from '../../reminders-calendar.service';

@Component({
  selector: 'pp-reminders-calendar-month-view-cell',
  templateUrl: './reminders-calendar-month-view-cell.component.html',
  styleUrl: './reminders-calendar-month-view-cell.component.scss',
})
export class RemindersCalendarMonthViewCellComponent {
  @Input() ppLocale: string;
  @Input() ppDay: any;
  @Input() ppView: CalendarView;
  @Output() ppViewChange = new EventEmitter<CalendarView>();
  @Input() ppViewDate: Date;
  @Output() ppViewDateChange = new EventEmitter<Date>();

  reminders: TReminder[] = [];

  constructor(
    private remindersCalendarService: RemindersCalendarService,
    private modalService: ModalService,
  ) {}

  ngOnChanges(): void {
    this.setReminders();
  }

  openDay(day: Date, event: MouseEvent): void {
    event.stopPropagation();

    this.remindersCalendarService.changeMode(CalendarView.Day);
    this.ppViewDateChange.emit(day);
  }

  createReminder(): void {
    this.modalService.setData<TReminderModalData>({
      pointId: null,
      timestamp: this.ppDay.date.getTime(),
      skipTime: true,
    });
    this.modalService.showModal(PointReminderModalComponent);
  }

  private setReminders(): void {
    this.reminders = this.remindersCalendarService.sortReminders(
      this.ppDay.events.map((event) => event.reminder),
    );
  }
}
