import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { SiteTableColumnsDataService } from 'src/app/project/modules/site/site-table/site-table-columns-data.service';
import { SiteService } from 'src/app/project/modules/site/site.service';
import { PlanDataService } from '../../plan/plan-data.service';
import { PointHalfModalService } from '../../points/point-half-modal/point-half-modal.service';
import { UserService } from '../../user/user.service';

import { ClickOutsideHandler } from '@core/services';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PreviousPageService } from 'src/app/core/services/previous-page.service';
import { ActiveWorkspaceService } from 'src/app/project/modules/user/active-workspace.service';
import { ScreenService } from '../../../../core/services/window/screen.service';
import { SiteRedirectService } from '../../auth/site-redirect.service';
import { PointAttachmentsService } from '../../points/point-modal/point-attachments/point-attachments.service';
import { SET_SIDE_PANEL_SERVICE } from './side-panel-store';
import { TSidePanel } from './side-panel.model';

@Injectable({
  providedIn: 'root',
})
export class SidePanelService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private readonly _accountPanelDestroy$ = new Subject<void>();
  readonly accountPanelDestroy$ = this._accountPanelDestroy$.asObservable();
  private clickOutsideHandler: ClickOutsideHandler;

  sidePanel: TSidePanel = {
    expanded: false,
    notificationsExpanded: false,
    expandedInitially: false,
    hidden: false,
    rightPanelExpanded: false,
    deviceType: undefined,
  };

  collapsedAccounts: {
    [accountId: string]: boolean;
  } = {};
  url = '';
  dataFetched = this.siteService.getFetched();
  paramId: string = null;
  isMobile: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
    private planDataService: PlanDataService,
    private pointHalfModalService: PointHalfModalService,
    private siteTableColumnsDataService: SiteTableColumnsDataService,
    private siteService: SiteService,
    private screenService: ScreenService,
    private activeWorkspaceService: ActiveWorkspaceService,
    private pointAttachmentsService: PointAttachmentsService,
    private siteRedirectService: SiteRedirectService,
    private previousPageService: PreviousPageService,
  ) {
    this.screenService.isMobile$.pipe(takeUntil(this.destroy$)).subscribe((isMobile) => {
      this.isMobile = isMobile;
    });

    SET_SIDE_PANEL_SERVICE(this);
  }

  getSidePanel(): TSidePanel {
    return this.sidePanel;
  }

  setSidePanel({
    expanded = undefined,
    notificationsExpanded = undefined,
  }: {
    expanded?: boolean;
    notificationsExpanded?: boolean;
  } = {}): void {
    if (expanded !== undefined) {
      this.sidePanel.expanded = expanded;
    }

    if (notificationsExpanded !== undefined) {
      this.sidePanel.notificationsExpanded = notificationsExpanded;
    }
  }

  collapsePanel(): void {
    this.setSidePanel({ expanded: false, notificationsExpanded: false });
    this.setRightPanelExpanded(false);

    this._accountPanelDestroy$.next();
  }

  setSidePanelOptions({
    expanded = undefined,
    expandedInitially = undefined,
    hidden = undefined,
  }: {
    expanded?: boolean;
    expandedInitially?: boolean;
    hidden?: boolean;
  } = {}): TSidePanel {
    if (expanded !== undefined) {
      this.sidePanel.expanded = expanded;
    }

    if (expandedInitially !== undefined) {
      this.sidePanel.expandedInitially = expandedInitially;
    }

    if (hidden !== undefined) {
      this.sidePanel.hidden = hidden;
    }

    return this.getSidePanel();
  }

  initSidePanel({
    expandedInitially = undefined,
  }: {
    expandedInitially?: boolean;
  } = {}): void {
    // FIXME It seems that a part of this method should be controlled by subscription.
    // take(1) is used to avoid breaking current logic
    this.screenService
      .observeOn$(['isMobile', 'isDesktop'])
      .pipe(take(1))
      .subscribe(({ isMobile, isDesktop }) => {
        if (isDesktop) {
          this.setSidePanelOptions({
            expanded: expandedInitially ? expandedInitially : false,
            expandedInitially: expandedInitially ? expandedInitially : false,
            hidden: false,
          });
        } else {
          this.setSidePanelOptions({ expanded: false, expandedInitially: false, hidden: isMobile });
        }
      });
  }

  backToFleet(): void {
    this.clearDataForPreviousPages();
    const url = this.previousPageService.navToPreviousUrl();
    this.router.navigate([url]);
  }

  backToCalendar(): void {
    this.clearDataForPreviousPages();
    const url = this.previousPageService.navToPreviousUrl();

    const urlData = url.split('?');

    if (urlData[1]) {
      const mode = urlData[1].split('=')[1];

      this.router.navigate([urlData[0]], { queryParams: { mode: mode } });
    } else {
      this.router.navigate([urlData[0]]);
    }
  }

  clearDataForPreviousPages(): void {
    this.sidePanel.notificationsExpanded = false;

    this.collapsePanel();

    this.pointHalfModalService.hideModal();
    this.planDataService.clearPlan();
    this.planDataService.clearPointPlan();
    this.siteTableColumnsDataService.clearColumns({ data: true });
  }

  switchSite(workspaceId: string): void {
    const canSwitch = this.canSwitchSite(workspaceId);
    this.previousPageService.navToPreviousUrl();

    if (canSwitch) {
      this.sidePanel.notificationsExpanded = false;

      this.collapsePanel();

      this.pointHalfModalService.hideModal();

      // TODO: events
      this.planDataService.clearPlan();
      this.planDataService.clearPointPlan();
      // ---
      this.siteTableColumnsDataService.clearColumns({ data: true });

      if (workspaceId) {
        this.activeWorkspaceService.updateActiveWorkspaceId(workspaceId);
        this.router.navigate(['/site', workspaceId]);
      } else {
        const newRoute = this.siteRedirectService.findNewRoute();
        this.router.navigate(newRoute);
      }
    }
  }

  goToOverview(offline: boolean): void {
    if (this.pointAttachmentsService.getAttachmentUploading()) {
      return;
    }

    const routerLink = offline ? null : '/site/overview';

    this.router.navigate([routerLink]);
  }

  goToTimeline(offline: boolean): void {
    if (this.pointAttachmentsService.getAttachmentUploading()) {
      return;
    }

    const routerLink = offline ? null : '/site/timeline';

    this.router.navigate([routerLink]);
  }

  goToFleetManagement(): void {
    if (this.pointAttachmentsService.getAttachmentUploading()) {
      return;
    }

    const routerLink = '/fleet-management';

    this.router.navigate([routerLink]);
  }

  getCollapsedAccounts(): { [accountId: string]: boolean } {
    return this.collapsedAccounts;
  }

  setCollapsedAccount(accountId: string): void {
    this.collapsedAccounts[accountId] = !this.collapsedAccounts[accountId];
  }

  setParams(url: string, paramId: string): void {
    this.url = url;
    this.paramId = paramId;
  }

  setRightPanelExpanded(expanded: boolean): void {
    this.sidePanel.rightPanelExpanded = expanded;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  canSwitchSite(workspaceId: string): boolean {
    if (this.pointAttachmentsService.getAttachmentUploading()) {
      return false;
    }

    if (!this.router.url.startsWith('/site')) {
      return true;
    }

    if (workspaceId !== this.paramId && this.dataFetched.dataFetched) {
      return true;
    }

    if (!workspaceId) {
      return true;
    }

    return false;
  }

  createClickOutsideHandler(
    nativeElement: HTMLElement,
    destroy$: Subject<void>,
  ): ClickOutsideHandler {
    this.clickOutsideHandler = new ClickOutsideHandler(nativeElement, destroy$);

    return this.clickOutsideHandler;
  }

  getClickOutsideHandler(): ClickOutsideHandler {
    return this.clickOutsideHandler;
  }
}
