import { cloneDeep } from 'lodash';

import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, timer } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TAllFilters, TFilters } from './site-filter.model';

import { ActiveService } from 'src/app/project/services/active/active.service';
import { SearchKeywordService } from './search-keyword.service';
import { SitePointFilterService } from './site-point-filter.service';

import { logEventInGTAG } from '../../services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from '../../services/analytics/google-analytics.consts';
import { EIconPath } from '../../shared/enums/icons.enum';
import { EStore } from '../../shared/enums/store.enum';
import { CustomTableService } from '../site/site-table/custom-table/custom-table.service';
import { checkCustomWorkspaceId } from '../workspace/workspace';
import { EWorkspaces } from '../workspace/workspaces.enum';
import { translate } from '../../features/translate/translate';

@Component({
  selector: 'pp-site-filter',
  templateUrl: './site-filter.component.html',
  styleUrls: ['./site-filter.component.scss'],
})
export class SiteFilterComponent implements OnInit, OnDestroy {
  @Input() ppIsReminder = false;

  private readonly destroy$ = new Subject<void>();
  readonly filter$ = new Subject<string>();

  filters: TFilters;
  hovered = false;
  focused = false;
  placeholder = '';

  private applyFiltersGATimeoutActivated = false;
  private applyFiltersGATimerMs = 60;

  private siteFilters$ = new Observable<TAllFilters>();
  private workspaces$ = new Observable<TWorkspacesById>();
  private workspaces: TWorkspacesById;
  workspaceId: string;
  private allFilters: TAllFilters;
  EWorkspaces = EWorkspaces;
  EIconPath = EIconPath;

  constructor(
    private store: Store<{
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private sitePointFilterService: SitePointFilterService,
    private activeService: ActiveService,
    private searchKeywordService: SearchKeywordService,
    private ngZone: NgZone,
    private customTableService: CustomTableService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters: TAllFilters) => {
      this.allFilters = allFilters;
      this.filters = cloneDeep(this.allFilters?.[this.workspaceId]);
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces: TWorkspacesById) => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();
      this.workspaces = workspaces;

      if (!this.workspaceId) {
        this.workspaceId = checkCustomWorkspaceId();
      }

      this.filters = cloneDeep(this.allFilters?.[this.workspaceId]);
    });
  }

  ngOnInit(): void {
    this.filter$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        tap((keyword) => {
          this.filterByName(keyword);
        }),
      )
      .subscribe();

    this.setPlaceholder();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  setPlaceholder(): void {
    if (this.ppIsReminder) {
      this.placeholder = translate('search_for_reminder');
    } else {
      this.placeholder =
        this.workspaceId === EWorkspaces.OVERVIEW
          ? translate('search_overview')
          : translate('search_this_site');
    }
  }

  clearSearchKeywords(): void {
    const table = this.customTableService.getTable();

    this.filters.keyword = '';
    this.filters.pointsWithKeyword = [];

    if (table) {
      table.setKeyword(this.filters.keyword);
    }

    this.searchKeywordService.setKeyword(this.filters.keyword, []);
    this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });
  }

  setHovered(isHovered: boolean): void {
    this.hovered = isHovered;
  }

  setFocused(isFocused: boolean): void {
    this.focused = isFocused;
  }

  private filterByName(keyword: string): void {
    const table = this.customTableService.getTable();

    if (table) {
      table.setKeyword(keyword);
    }

    const workspaceIds = [];

    if (this.workspaceId && this.workspaceId !== EWorkspaces.OVERVIEW) {
      workspaceIds.push(this.workspaceId);
    } else {
      Object.keys(this.workspaces).forEach((workspaceId) => {
        workspaceIds.push(workspaceId);
      });
    }

    this.searchKeywordService
      .searchCommentKeyword(keyword, workspaceIds)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const pointsWithKeywords = response.pointIds;

          this.searchKeywordService.setKeyword(keyword, pointsWithKeywords);
          this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });

          if (!this.applyFiltersGATimeoutActivated) {
            logEventInGTAG(EGoogleEventSite.SITE__SEARCH__KEYWORDS, {
              event_category: EGoogleEventCategory.SITE,
            });

            this.applyFiltersGATimeoutActivated = true;

            this.ngZone.runOutsideAngular(() => {
              timer(this.applyFiltersGATimerMs)
                .pipe(
                  takeUntil(this.destroy$),
                  tap(() => {
                    this.applyFiltersGATimeoutActivated = false;
                  }),
                )
                .subscribe();
            });
          }
        }),
      )
      .subscribe();
  }
}
