import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { reminderRoutes } from './reminders.routes';

import { FiltersModule } from '../filters/filters.module';
import { LayoutModule } from '../layout/layout.module';
import { SupportedBrowsersModule } from '../supported-browsers/supported-browsers.module';

import { CommonModule } from '@angular/common';
import { CalendarModule } from 'angular-calendar';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { CommonComponentsModule } from '../../common-components/common-components.module';
import { ButtonModule } from '../../components/button/button.module';
import { IconModule } from '../../components/icon/icon-module';
import { DatepickerModule } from '../../components/input/datepicker/datepicker.module';
import { HideDropdownModule } from '../../components/input/hide-dropdown/hide-dropdown.module';
import { ModalModule } from '../../components/modal/modal.module';
import { SaveIndicatorModule } from '../../components/save-indicator/save-indicator.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { PlaceholdersModule } from '../placeholders/placeholders.module';
import { ReminderPopupModule } from '../reminder-popup/reminder-popup.module';
import { RemindersCalendarDayViewCellComponent } from './reminders-calendar/day-view/reminders-calendar-day-view-cell/reminders-calendar-day-view-cell.component';
import { RemindersCalendarMonthViewCellComponent } from './reminders-calendar/month-view/reminders-calendar-month-view-cell/reminders-calendar-month-view-cell.component';
import { ReminderCalendarControlButtonsComponent } from './reminders-calendar/reminder-calendar-control-buttons/reminder-calendar-control-buttons.component';
import { ReminderCalendarElementComponent } from './reminders-calendar/reminder-calendar-element/reminder-calendar-element.component';
import { RemindersCalendarComponent } from './reminders-calendar/reminders-calendar.component';
import { ReminderCalendarWeekViewHeaderCellComponent } from './reminders-calendar/week-view/reminder-calendar-week-view-header/reminder-calendar-week-view-header-cell/reminder-calendar-week-view-header-cell.component';
import { ReminderCalendarWeekViewHeaderComponent } from './reminders-calendar/week-view/reminder-calendar-week-view-header/reminder-calendar-week-view-header.component';
import { RemindersCalendarWeekViewCellComponent } from './reminders-calendar/week-view/reminders-calendar-week-view-cell/reminders-calendar-week-view-cell.component';
import { RemindersCalendarWeekViewTimeLabelCellComponent } from './reminders-calendar/week-view/reminders-calendar-week-view-time-label-cell/reminders-calendar-week-view-time-label-cell.component';
import { RemindersFilterService } from './reminders-filter.service';
import { RemindersComponent } from './reminders/reminders.component';
import { RemindersCalendarListComponent } from './reminders-calendar/reminders-calendar-list/reminders-calendar-list.component';
import { SearchBoxModule } from '../../components/search-box/search-box.module';

@NgModule({
  declarations: [
    RemindersComponent,
    RemindersCalendarComponent,
    ReminderCalendarControlButtonsComponent,
    RemindersCalendarMonthViewCellComponent,
    ReminderCalendarElementComponent,
    RemindersCalendarWeekViewCellComponent,
    RemindersCalendarWeekViewTimeLabelCellComponent,
    ReminderCalendarWeekViewHeaderComponent,
    ReminderCalendarWeekViewHeaderCellComponent,
    RemindersCalendarDayViewCellComponent,
    RemindersCalendarListComponent,
    ReminderCalendarControlButtonsComponent,
    ReminderCalendarElementComponent,
  ],
  imports: [
    RouterModule.forChild(reminderRoutes),
    FormsModule,
    CommonModule,
    SaveIndicatorModule,
    DatepickerModule,
    SaveIndicatorModule,
    PipesModule,
    ButtonModule,
    LayoutModule,
    SupportedBrowsersModule,
    FiltersModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    ModalModule,
    IconModule,
    PlaceholdersModule,
    CommonComponentsModule,
    CalendarModule,
    ReminderPopupModule,
    HideDropdownModule,
    SearchBoxModule,
  ],
  providers: [RemindersFilterService],
  exports: [],
})
export class RemindersModule {}
